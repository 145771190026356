<template>
  <b-container class="fields" aria-labelledby="entity-table" aria-describedby="entity-table">
    <b-row v-for="field of filteredFields" :key="field.name" :id="field.name" class="field">
      <b-col col lg="4" md="12" class="label">{{ field.label }}</b-col>
      <b-col col lg="8" md="12" class="value">
        <span
          v-if="field.type !== $INSIGHT('INPUT_TYPES').IMAGE && ['', null].indexOf(field.value) !== -1"
        />

        <span
          v-else-if="[
            $INSIGHT('INPUT_TYPES').DATE,
            $INSIGHT('INPUT_TYPES').DATETIME,
            $INSIGHT('INPUT_TYPES').TIME,
            $INSIGHT('INPUT_TYPES').CALCULATED_DATE
          ].indexOf(field.type) !== -1"
        >
          {{ dateString(field.value, field.type) }}
        </span>

        <span
          v-else-if="field.type === $INSIGHT('INPUT_TYPES').RADIO"
        >
          {{ field.meta.choices.find(choice => choice.value === field.value).text }}
        </span>

        <span
          v-else-if="field.type === $INSIGHT('INPUT_TYPES').URL"
          v-encode-hyperlink-html="parseUrl(field.value)"
        />

        <span
          v-else-if="field.validation.format === $INSIGHT('VALIDATION_FORMAT').CURRENCY">
          {{ currencyString(field.value) }}
        </span>

        <!-- TODO JSON -->
        <span
          class="downloadable"
          v-else-if="['file', 'image'].indexOf(field.type) !== -1"
          @click="downloadAttachment(field)"
        >
          <b-spinner v-if="imageLoading" variant="secondary" small label="Loading..."></b-spinner>
          <LaTriangle v-if="attachmentError" class="la" v-b-tooltip.hover :title="$INSIGHT('FIELD_LIST').IMAGE_DOWNLOAD.ERROR_TOOLTIP"></LaTriangle>
          <b-img v-if="isShowImagePreview(field)" class="mb-1 image-preview" left rounded :src="imagePreview[field.name]"/>
          <b-btn v-else-if="isShowDownloadButton(field)" size="sm">
            <LaDownload v-if="isReadyToDownload(field)" class="la"/>
          </b-btn>
          <b-spinner v-if="isPreparingToDownload(field)" variant="secondary" small :label="$INSIGHT('FIELD_LIST').IMAGE_DOWNLOAD.LOADING_TEXT"></b-spinner>
        </span>
        <span v-else>{{ field.value }}</span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import LaDownload from '@/assets/la-download.svg'
import LaTriangle from '@/assets/la-warning-triangle.svg'
import linkifyHtml from 'linkifyjs/html'

import DateTimeStringMixin from '@/modules/insight/mixins/DateTimeStringMixin'
import CurrencyStringMixin from '@/modules/insight/mixins/CurrencyStringMixin'

export default {
  name: 'fields-list',
  components: {
    LaDownload, LaTriangle
  },
  mixins: [DateTimeStringMixin, CurrencyStringMixin],
  props: {
    fields: {
      type: Array,
      default: () => ([])
    },
    imagePreview: {
      type: Object,
      default: () => ({})
    },
    imageLoading: {
      type: Boolean
    },
    attachmentError: {
      type: Boolean
    },
    downloadAttachmentLoading: {
      type: Boolean
    }
  },
  data: () => ({
    parseUrl (value) {
      return linkifyHtml(value, { target: '_blank' })
    },
    attachmentLoading: {},
    currentClickedField: false
  }),
  methods: {
    /***
     * This method is to trigger the action of download file via emit.
     * @param field
     */
    downloadAttachment (field) {
      this.currentClickedField = field.name
      this.$emit('download', field)
    },
    /***
     * This method is to check if the downloading is loading, pending backend response.
     * @param field
     * @returns {boolean}
     */
    checkDownloadAttachmentLoading (field) {
      const isFieldJustBeenClicked = field.name === this.currentClickedField
      if (isFieldJustBeenClicked && this.downloadAttachmentLoading) {
        return true
      } else {
        return false
      }
    },
    /***
     * This method is to check if the field has an image to preview.
     * @param field
     * @returns {boolean|*}
     */
    isShowImagePreview (field) {
      return field.type === 'image' && this.imagePreview[field.name] !== undefined
    },
    /**
     * This method is to check if the field should display a download icon.
     * @param field
     * @returns {boolean}
     */
    isShowDownloadButton (field) {
      return field.type === 'file' && field.value === this.$INSIGHT('SPECIAL_VALUES').DOWNLOAD &&
        !this.checkDownloadAttachmentLoading(field)
    },
    /***
     * This method is to see if attachment has went into an error. If yes, display the triangle error icon.
     * @param field
     * @returns {boolean}
     */
    isReadyToDownload (field) {
      return !this.attachmentError && !this.checkDownloadAttachmentLoading(field)
    },
    /***
     * This method is to see if the downloading is in progress, controlling the spinner icon.
     * @param field
     * @returns {boolean}
     */
    isPreparingToDownload (field) {
      return field.type === 'file' && field.value === this.$INSIGHT('SPECIAL_VALUES').DOWNLOAD &&
          this.checkDownloadAttachmentLoading(field)
    }
  },
  computed: {
    filteredFields () {
      return this.fields
    }
  }
}
</script>

<style lang="scss" scoped>
.fields {
  margin: -0 0;
  width: 100%;

  .field {
    border-bottom: 1px solid #ececec;
    padding: 5px 5px;

    &:hover {
      background: #ececec;
    }

    .label {
      font-weight: 400;
      padding-left: 5px;
    }

    .value {
      padding-left: 5px;
      font-weight: 300;
      /deep/ a {
        color: #0056b3;
      }
    }
  }
}

.image-preview {
  cursor: pointer;
  height: 128px;
}
</style>
