<template>
    <div :class="buttonArrangementClass" @click.stop="$emit('close')">

        <div class="circle edit" @click.prevent.stop="$emit('openFormModal')" v-b-tooltip:query-canvas.hover :title="'Edit Form'">
          <div class="inside">
            <la-edit />
          </div>
        </div>

        <div class="circle set-primary" @click.prevent.stop="$emit('setPrimary')" v-if="isForEntity" v-b-tooltip:query-canvas.hover :title="item.primary === false ? 'Set as Primary' : 'Remove as Primary'">
          <div class="inside">
            <primary-item />
          </div>
        </div>

        <div class="circle set-mandatory" @click.prevent.stop="$emit('setMandatory')" v-if="showMandatoryButton" v-b-tooltip:query-canvas.hover :title="item.isMandatory === false ? 'Set as Mandatory' : 'Remove as Mandatory'">
          <div class="inside asterisk-icon">
            <asterisk />
          </div>
        </div>

        <div class="circle delete" @click.prevent.stop="$emit('remove')" v-b-tooltip:query-canvas.hover :title="removeItemText">
          <div class="inside">
            <la-trash />
          </div>
        </div>

      </div>
</template>

<script>
import Vue from 'vue'
import LaTrash from '@/assets/la-trash.svg'
import LaEdit from '@/assets/la-edit.svg'
import Asterisk from '@/assets/asterisk.svg'
import PrimaryItem from '@/assets/primary-item.svg'

export default {
  name: 'entity-actions-for-form',
  components: {
    LaTrash,
    LaEdit,
    Asterisk,
    PrimaryItem
  },
  props: {
    item: {
      required: true
    },
    isFor: {
      default: 'entityActions',
      required: true
    }
  },
  data: () => ({
    wordRemove: Vue.prototype.$LOCAL('COMMON_WORD').REMOVE,
    wordEntity: Vue.prototype.$LOCAL('Entity'),
    wordLink: Vue.prototype.$LOCAL('Link')
  }),
  computed: {
    isForEntity () {
      return this.isFor === 'entityActions'
    },
    removeItemText () {
      const wordRemove = this.wordRemove
      const wordEntity = this.wordEntity
      const wordLink = this.wordLink

      const item = this.isForEntity ? wordEntity : wordLink
      return `${wordRemove} ${item}`
    },
    buttonArrangementClass () {
      const entityShowMandatoryButton = 'inner-entity-show-mandatory-btn'
      const entityHideMandatoryButton = 'inner-entity-hide-mandatory-btn'
      const classForLink = 'inner-link-composite-form'
      const classForEntity = this.showMandatoryButton ? entityShowMandatoryButton : entityHideMandatoryButton
      return this.isForEntity ? classForEntity : classForLink
    },
    isPrimary () {
      return this.item?.primary
    },
    showMandatoryButton () {
      return this.isForEntity && !this.isPrimary && this.item.canSetAsMandatory
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/modules/insight/components/canvas/EntityActionsButton/entityactions.scss';
</style>
