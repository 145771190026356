<template>
  <div class="px-4 pt-3 pb-2 bg-light">
    <div :class="tabToggle">
      <!-- TOGGLE BUTTON -->
      <div class="cursor-pointer row d-flex justify-content-center" @click="slidingTab.toggle = !slidingTab.toggle" tabindex="0" :aria-label="slidingTab.title">
        <ArrowDown aria-hidden="true" :class="arrowAnimation" class="mr-1"/>
        <h5 class="font-weight-light">
          {{ slidingTab.title }}
        </h5>
      </div>
      <!-- END OF TOGGLE BUTTON -->

      <!-- INPUT FORM -->
      <div :class="overflowClass">
        <b-form class="mx-2" @submit.prevent>
          <!-- TITLE INPUT -->
          <b-form-group :label="titleLabel" class="text-left" :label-for="titleLabel">
            <b-form-input type="text" v-model="titleModel" :state="titleErrorState"
              class="shadow-sm fade-in-fast" :disabled="!slidingTab.toggle || loading"
              :id="titleLabel" :aria-label="$INSIGHT('QUERY').FORM.NAME" required/>
              <b-form-invalid-feedback :state="titleErrorState">
                {{titleErrorMessage}}
              </b-form-invalid-feedback>
          </b-form-group>
          <!-- END OF TITLE INPUT -->

          <!-- QUERY'S DESCRIPTION AND CATEGORY INPUT -->
          <span v-if="isForQuery">
            <b-form-group :label="`${$INSIGHT('QUERY').FORM.DESCRIPTION}`" class="text-left" :label-for="$INSIGHT('QUERY').FORM.DESCRIPTION">
              <b-form-textarea v-model="query.description" class="shadow-sm fade-in-fast" :disabled="!slidingTab.toggle || loading"
                :id="$INSIGHT('QUERY').FORM.DESCRIPTION" />
            </b-form-group>
            <b-form-group :label="`${$INSIGHT('QUERY').FORM.CATEGORY} (blank='${userData.username}')`" :label-for="$INSIGHT('QUERY').FORM.CATEGORY"
              class="text-left">
              <b-form-input list="category-list" v-model="query.category" :id="$INSIGHT('QUERY').FORM.CATEGORY" :disabled="!slidingTab.toggle || loading" />
              <b-form-datalist id="category-list" :options="queryMeta.category"/>
            </b-form-group>
          </span>
          <!-- END OF QUERY'S DESCRIPTION AND CATEGORY INPUT -->

          <!-- ACCESS GROUP PERMISSION INPUT -->
          <b-form-group :label="accessGroupsLabel" :label-for="accessGroupsLabel" class="text-left">
            <span v-if="isForQuery">
              <b-form-radio v-model="accessGroupOption.data" name="access-groups-options" value="O" class="ml-1 mb-2">Public</b-form-radio>
              <b-form-radio v-model="accessGroupOption.data" name="access-groups-options" value="-" class="ml-1 mb-1">Restricted to groups</b-form-radio>
            </span>

            <div :class="checkboxOptionContainer">
              <div class="checkbox-option">
                <span v-if="(validAccessGroups.length === 0) && isForQuery" class="info-text">{{noPermittedGroupErrorMsg}}</span>
                <!-- ACCESS GROUP OPTION FOR QUERY -->
                <b-form-checkbox
                  v-else-if="isForQuery"
                  v-for="detail in queryMeta['access_group']"
                  v-model="accessGroups"
                  :key="detail.gid"
                  :value="detail.gid"
                  name="access-groups-option"
                  class="mb-1"
                  :disabled="disableAccessGroupOption"
                >
                  {{ detail.name }}
                </b-form-checkbox>
                <!-- END OF ACCESS GROUP OPTION FOR QUERY -->

                <!-- ==================================== -->

                <!-- ACCESS GROUP OPTION FOR FORM -->
                <PermittedGroup
                  v-if="!isForQuery"
                  :disabled="loading"
                  :ref="$FORM('REFS').PERMITTED_GROUP"
                  :parentData="compositeForm"
                  @changePermittedGroup="setFormPermittedGroups"
                />
                <!-- END OF ACCESS GROUP OPTION FOR FORM -->

              </div>
            </div>
            <small v-if="accessGroupsError && !loading" class="text-danger">
              {{accessGroupsErrorMsg}}
            </small>
          </b-form-group>
          <!-- END OF ACCESS GROUP PERMISSION INPUT -->

        </b-form>
      </div>
      <!-- END OF INPUT FORM -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ArrowDown from '@/assets/angle-up-solid.svg'
import PermittedGroup from '@/modules/forms/components/PermittedGroup'

export default {
  name: 'sliding-tab-area',
  components: {
    ArrowDown,
    PermittedGroup
  },
  computed: {
    ...mapState('auth', {
      userData: 'userData'
    }),
    ...mapState('insight/queries', {
      query: 'query'
    }),
    tabToggle () {
      const toggleUpforQuery = 'toggle-up-for-query'
      const toggleUpforForm = 'toggle-up-for-form'
      const toggleDown = 'toggle-down'
      const toggleUp = this.isForQuery ? toggleUpforQuery : toggleUpforForm

      return this.slidingTab.toggle ? toggleUp : toggleDown
    },
    arrowAnimation () {
      return this.slidingTab.toggle ? 'arrow-icon-up' : 'arrow-icon-down'
    },
    titleLabel () {
      const queryLabel = this.$INSIGHT('QUERY').FORM.NAME
      const formLabel = this.$FORM('TEXT').TITLE.TITLE_FORM
      return this.isForQuery ? queryLabel : formLabel
    },
    titleModel: {
      get: function () {
        const queryTitle = this.query?.name
        const formTitle = this.compositeForm?.title
        return this.isForQuery ? queryTitle : formTitle
      },
      set: function (newValue) {
        if (this.isForQuery) {
          this.query.name = newValue
        } else {
          this.compositeForm.title = newValue
        }
      }
    },
    titleErrorMessage () {
      const queryTitleErrorMsg = this.queryNameError.message
      const formTitleErrorMsg = this.formTitleError.message
      return this.isForQuery ? queryTitleErrorMsg : formTitleErrorMsg
    },
    checkboxOptionContainer () {
      const query = this.$INSIGHT('MODEL_TYPES').QUERY
      const form = this.$INSIGHT('MODEL_TYPES').FORM
      const modelType = this.isForQuery ? query : form
      return `checkbox-option-container-for-${modelType}`
    },
    accessGroupsLabel () {
      const queryAccessGroupsLabel = this.$INSIGHT('QUERY').FORM.ACCESS_GROUP
      const formAccessGroupsLabel = this.$FORM('TEXT').TITLE.PERMITTED_GROUPS
      const accessGroupsLabel = this.isForQuery ? queryAccessGroupsLabel : formAccessGroupsLabel
      return `${accessGroupsLabel}*`
    },
    accessGroupsErrorMsg () {
      const queryError = this.$INSIGHT('QUERY').ERROR.PLEASE_CHOOSE_ONE_ACCESS_GROUP
      const formError = this.$FORM('ERROR').EMPTY_PERMITTED_GROUPS
      return this.isForQuery ? queryError : formError
    },
    noPermittedGroupErrorMsg () {
      const queryError = this.$INSIGHT('QUERY').ERROR.NO_ACCESS_GROUPS
      const formError = this.$FORM('ERROR').COMPOSITE_FORM.NO_PERMITTED_GROUPS
      return this.isForQuery ? queryError : formError
    },
    overflowClass () {
      return this.slidingTab.toggle ? 'overflow-enable' : 'overflow-disable'
    }
  },
  props: {
    loading: {
      default: false
    },
    isForQuery: {
      default: true
    },
    slidingTab: {
      default: () => ({
        title: '',
        toggle: true
      })
    },
    titleErrorState: {
      default: null
    },
    validAccessGroups: {
      default: () => []
    },
    disableAccessGroupOption: {
      default: true
    },
    accessGroupOption: {
      default: () => ({
        data: 'O'
      })
    },
    accessGroupsError: {
      default: ''
    },
    // ------------------
    // BEGIN QUERY PROPS
    nameError: {
      default: false
    },
    queryMeta: {
      default: () => ({
        category: [],
        access_group: []
      })
    },
    queryNameError: {
      default: () => ({
        state: null,
        message: ''
      })
    },
    // END OF QUERY PROPS
    // ------------------
    // BEGIN FORM PROPS
    formTitleError: {
      default: () => ({
        state: null,
        message: ''
      })
    },
    compositeForm: {
      default: () => ({
        title: '',
        model: null,
        permitted_groups: [],
        permitted_groups_option: [],
        forms: []
      })
    },
    formMode: {
      default: 'create'
    }
    // END OF FORM PROPS
    // ------------------
  },
  data: () => ({
    accessGroups: []
  }),
  methods: {
    submitData () {
      this.$emit('submitData')
    },
    setFormPermittedGroups (value) {
      this.$emit('setFormPermittedGroups', value)
    },
    setFormPermittedGroupsOption (givenPermittedGroupsOption) {
      const isCreate = this.formMode.includes('create')
      this.$refs.PermittedGroup.setPermittedGroupsOption(givenPermittedGroupsOption, isCreate)
      if (!isCreate) {
        this.$refs.PermittedGroup.permittedGroups = this.compositeForm.permitted_groups
      }
    }
  },
  watch: {
    accessGroups: {
      immediate: true,
      handler: function (value) {
        this.$emit('emitQueryAccessGroups', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.arrow-icon-up {
  fill: #9E9E9E;
  height: 25px;
  margin-bottom: -5px;
  transform: rotate(180deg);
  transition: all 0.2s;

    &:hover {
      fill: rgb(100, 100, 100);
    }
}

.arrow-icon-down {
  fill: #9E9E9E;
  height: 25px;
  margin-bottom: -5px;
  transition: all 0.2s;
    &:hover {
        fill: rgb(100, 100, 100);
      }
}

.toggle-up-for-query {
  height: 465px;
  transition: height 1s;
}

.toggle-up-for-form {
  height: 285px;
  transition: height 1s;
}

.toggle-down {
  height: 27px;
  transition: height 1s;
}

.checkbox-option-container-for-query {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-left: 27px;
  margin-bottom: 5px;
  max-height: 110px;
  overflow: auto;
}

.checkbox-option-container-for-form {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-bottom: 5px;
  max-height: 110px;
  overflow: auto;
}

.checkbox-option {
  padding: 8px 0 5px 10px;
}

.info-text {
  color: #aaa;
}

.overflow-enable {
  max-height: 400px;
  margin: 0px 5px 0px 5px;
  overflow: auto;
}

.overflow-disable {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(201, 201, 201);
}

</style>
