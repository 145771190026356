<template>
  <div id="form-field">
    <!-- UPPER AREA OF THE CURRENT GROUP NAME AND ADD/DELETE TAB BUTTON -->
    <div class="d-flex justify-content-between mb-3" v-if="currentTab && !isViewModel">
      <HeaderEdit
        :ref="$FORM('REFS').HEADER_EDIT"
        :formMode="mode"
        :theHeader="currentTab"
        :error="error"
        :headerOf="$FORM('HEADER_TYPE').TAB"
        :enableFormBuilder="enableFormBuilder"
        @resetError="resetError"
        v-show="showHeaderEdit"
      />

      <div v-if="enableFormBuilder">
        <b-button v-if="tabList.length > 1" @click="askRemoveTab" variant="danger" class="mr-2">
          <la-trash class="generic-icon"/> {{removeTabText}}
        </b-button>
        <b-button @click="addTab" variant="success" v-if="hasModelFields && !loading">
          <LaPlus class="generic-icon"/> {{addTabText}}
        </b-button>
      </div>
    </div>
    <!-- END OF UPPER AREA OF THE CURRENT GROUP NAME AND ADD/DELETE TAB BUTTON -->

    <!-- TAB BUTTON FOR GROUP(S) FIELD -->
    <b-nav pills class="d-flex justify-content-center mb-2">
      <b-nav-item
        v-for="(item, index) of formTabs"
        :draggable="enableFormBuilder"
        class="flex-fill text-center item"
        :class="{ 'small-font' : isViewModel,'cursor-move' : isViewModel }"
        :key="index"
        :active="checkPosition(index)"
        @mousedown="changeTabPosition(index)"
        @dragover="onDragOver(item, index, $event)"
        @dragend="finishDrag(item, index, $FORM('TEXT').WORD.TAB)"
        @dragstart="startDrag(item, $event, $FORM('TEXT').WORD.TAB)">
          &#9776; {{item.header}}
      </b-nav-item>
    </b-nav>
    <!-- END OF TAB BUTTON FOR GROUP(S) FIELD -->
    <b-overlay :class="{ 'background-danger' : (error === $FORM('ERROR').EMPTYTAB), 'field-area' : !isViewModel }" v-if="!formIsCreateEditModelAndHasNoField">
      <!-- EMPTY FIELD TEXT AND ADD FIELD BUTTON -->
      <div v-if="!currentTabLength && enableFormBuilder" class="empty-field-text">
        <span class="font-italic font-weight-light" v-if="!availableModelField.length && !loading">
          <p>
            {{$FORM('TEXT').FORMBUILDER_MISC.NO_FIELDS_AVAILABLE}}<br/>
            <b-button size="sm" class="mt-1" v-if="currentPosition" @click="askRemoveTab">{{$FORM('TEXT').BUTTON.REMOVE_TAB}}</b-button>
          </p>
        </span>
        <span
          v-else-if="availableModelField.length && !loading">
          <b-button variant="success" class="mt-1" @click="addField">
            <LaPlus class="generic-icon" /> {{addFieldText}}
          </b-button>
        </span>
      </div>
      <div v-else-if="!currentTabLength && !loading && !isViewModel" class="empty-field-text">
        <span class="font-italic font-weight-light">{{$FORM('TEXT').FORMBUILDER_MISC.TAB_IS_EMPTY}}</span>
      </div>
      <!-- END OF EMPTY FIELD TEXT AND ADD FIELD BUTTON -->

      <!-- ======FORM FIELD (AND DRAG AND DROP FORMBUILDER FIELD)====== -->
      <div v-for="(item, index) of currentTab.model_fields" :key="index" class="my-3" :draggable="enableFormBuilder"
        @mousedown="removeAnimation"
        @dragover="onDragOver(item, index, $event)"
        @dragend="finishDrag(item, index, $FORM('TEXT').WORD.FIELD)"
        @dragstart="startDrag(item, index, $event)">
        <div class="d-flex flex-row" v-if="enableFormBuilder">
          <span class="mr-2 mt-1 cursor-move">&#9776;</span>
          <b-form-select v-model="item.model_field" class="mr-2" :class="{ 'background-animation' : item.animation }">
            <option
              v-for="(item, index) of modelFieldOptions"
              :key="index"
              :value="item.name"
              :disabled="checkModelField(item.name)"
              :class="{disabledOption : checkModelField(item.name)}">
              {{item.label}}
            </option>
          </b-form-select>
          <b-button :variant="cogColour(item.logic)" @click="showLogicModal(item)" class="mr-1" v-b-tooltip:form-field.hover :title="$FORM('TEXT').TOOLTIP.FIELD_SETTINGS">
            <LaCog class="generic-icon"/>
          </b-button>
          <b-button variant="danger" @click="askRemoveField(index, item)">
            <la-trash class="generic-icon"/>
          </b-button>
        </div>
        <div v-else-if="isCreateEditModel && itemOptionsExists(item)">
          <entity-form-field
            class="form-field"
            v-model="item.options.value"
            :loading="loading"
            :id="item.options.name"
            :key="item.options.name"
            :field="item.options"
            :error="error"
            :dependentFields="getDependentFields(item.options.df_options)"
            @verifyField="verifyField"
            :blacklistedFileExt="blacklistedFileExt"
            :maxFileSize="maxFileSize"
          >
          </entity-form-field>
        </div>
      </div>
      <div v-if="isViewModel">
        <field-list
          :fields="currentTab.model_fields"
          @download="downloadAttachment"
          :imagePreview="imagePreview"
          :imageLoading="imageLoading"
          :attachmentError="attachmentError"
          :downloadAttachmentLoading="downloadAttachmentLoading" />
      </div>

      <b-button
        variant="success"
        @click="addField"
        class="mt-2 mb-2"
        v-if="(availableModelField.length && currentTabLength) && enableFormBuilder">
        <LaPlus class="generic-icon"/> {{addFieldText}}
      </b-button>
      <!-- ======END OF FORM FIELD (AND DRAG AND DROP FORMBUILDER FIELD)====== -->
    </b-overlay>

    <!-- BUTTON FOR BACK TO GENERIC VIEW (FOR VIEWING MODEL) -->
    <div class="mt-3 mb-3" v-if="showBackToGenericViewButton">
      <b-button @click="toGenericView" size="sm" pill variant="outline-secondary">
        <Arrow class="arrow-left" />
        {{$LOCAL('COMMON_WORD').BACK}}
      </b-button>
    </div>
    <!-- END OF BUTTON FOR BACK TO GENERIC VIEW (FOR VIEWING MODEL) -->

    <!-- MODAL FOR REMOVE TAB/FIELD -->
    <b-modal id="modalRemove" size="lg" :ok-title="`${$FORM('TEXT').BUTTON.CONFIRM_REMOVE}`" :cancel-title="`${$FORM('TEXT').BUTTON.CONFIRM_CANCEL}`" :title="`${$FORM('TEXT').WORD.REMOVE} ${dependentFieldsCondition} ${modalSetToRemove}`" ok-variant="success" cancel-variant="danger" @ok="confirmDelete">
      <template v-slot:modal-title>
        <span v-if="modalSetToRemove === $FORM('TEXT').WORD.TAB">{{$FORM('TEXT').WORD.REMOVE}} {{currentTab.header}} {{modalSetToRemove}}</span>
        <span v-if="modalSetToRemove === $FORM('TEXT').WORD.FIELD">{{$FORM('TEXT').WORD.REMOVE}} {{dependentFieldsCondition}} {{modalSetToRemove}}</span>
     </template>
      <p v-if="dependentFields.length > 0">
        <b-alert show variant="warning">
          <strong>{{$FORM('TEXT').WORD.THE}} {{$FORM('TEXT').QUESTION.DELETE_DEPENDENT_FIELDS}}</strong>
          <ul>
            <li v-for="fieldName in dependentFields" :key="fieldName">{{fieldName}}</li>
          </ul>
        </b-alert>
      </p>

      <p class="my-4">{{$FORM('TEXT').QUESTION.DELETE}}
        <span v-if="(modalSetToRemove === $FORM('TEXT').WORD.FIELD) && fieldToDelete.model_field" class="font-weight-bold">{{fieldToDelete.model_field.label}} field?</span>
        <span v-else class="font-weight-bold">{{currentTab.header}} {{$FORM('TEXT').WORD.TAB}}?</span>
      </p>
    </b-modal>
    <!-- END OF MODAL FOR REMOVE TAB/FIELD -->

    <!-- LOGIC SETTINGS MODAL AND THE IMPLEMENTATION OF THE LOGIC -->
    <LogicSettings
      :ref="$FORM('REFS').LOGIC_SETTINGS"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import LaPlus from '@/assets/la-plus.svg'
import LaTrash from '@/assets/la-trash.svg'
import LaCog from '@/assets/la-cog.svg'
import HeaderEdit from '@/modules/forms/components/HeaderEdit'
import EntityFormField from '@/modules/insight/components/EntityFormField'
import LogicSettings from '@/modules/forms/components/LogicComponents/LogicSettings'
import FieldList from '@/modules/insight/components/FieldList'
import Arrow from '@/assets/angle-up-solid.svg'

import { find, cloneDeep, remove, isEmpty } from 'lodash'

export default {
  name: 'form-field',
  components: {
    HeaderEdit,
    EntityFormField,
    LaPlus,
    LaTrash,
    LaCog,
    LogicSettings,
    FieldList,
    Arrow
  },
  props: {
    mode: {
      default: false
    },
    loading: {
      default: false
    },
    error: {
      default: false
    },
    formData: {
      default: false
    },
    missingRequiredFieldWarn: {
      default: false
    },
    emptyTabWarn: {
      default: false
    },
    emptyPermittedGroupsWarn: {
      default: ''
    },
    enableFormBuilder: {
      default: false
    },
    // Below are for downloading image & file in view Page of entity/link/field
    imagePreview: {
      type: Object,
      default: () => ({})
    },
    imageLoading: {
      default: false
    },
    attachmentError: {
      default: false
    },
    downloadAttachmentLoading: {
      default: false
    },
    isValidatePermittedGroups: {
      // if its a composite form, then permitted groups handling is done in another component
      default: true
    },
    showBackToGenericViewButton: {
      default: false
    },
    blacklistedFileExt: {
      type: Array,
      default: () => ([])
    },
    maxFileSize: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    availableModelField () {
      if (this.formData.model_fields && this.usedModelField) {
        const availableModelFieldArray = []
        this.formData.model_fields.forEach(item => {
          // populate model_field that still not used yet
          if (!this.usedModelField.includes(item.name)) availableModelFieldArray.push(item)
        })
        return availableModelFieldArray
      }
      return []
    },
    usedModelField () {
      if (this.formData.groups) {
        const usedModelFieldArray = []
        this.formData.groups.forEach(itemGroup => {
          itemGroup?.model_fields.forEach(itemField => {
            usedModelFieldArray.push(itemField?.model_field) // populate used model_field on each tab
          })
        })
        return usedModelFieldArray
      }
      return []
    },
    modelFieldOptions () {
      if (this.formData.model_fields) {
        return this.formData.model_fields
      } else {
        return []
      }
    },
    tabTotal () {
      if (!this.formData.groups) return 0
      return this.formData.groups.length
    },
    currentTab () {
      const defaultData = {
        header: false,
        model_fields: []
      }
      if (isEmpty(this.formData.groups)) return defaultData
      return this.formData.groups[this.currentPosition]
    },
    currentTabLength () {
      try {
        return this.formData.groups[this.currentPosition].model_fields.length // return how many model_field used in current tab
      } catch (error) {
        return false
      }
    },
    currentTabHeaderIsEmpty () {
      return !this.currentTab?.header
    },
    tabList () {
      if (this.formData.groups) {
        return this.formData.groups
      }
      return false
    },
    showHeaderEdit () {
      const createForm = this.$FORM('MODE').CREATE_FORM
      const editForm = this.$FORM('MODE').EDIT
      if (((this.mode === createForm) || (this.mode === editForm)) && !this.loading) {
        return true
      } else {
        return false
      }
    },
    hasModelFields () {
      if (this.formData.model_fields) {
        return this.formData.model_fields.length > 0
      } else {
        return false
      }
    },
    isViewModel () {
      return this.mode === this.$FORM('MODE').VIEW_MODEL
    },
    isCreateEditModel () {
      const editModel = (this.mode === this.$FORM('MODE').EDIT_MODEL)
      const createModel = (this.mode === this.$FORM('MODE').CREATE_MODEL)
      return editModel || createModel
    },
    isCreateEditForm () {
      const isCreateForm = this.mode === this.$FORM('MODE').CREATE_FORM
      const isEditForm = this.mode === this.$FORM('MODE').EDIT
      const isCreateEditForm = isCreateForm || isEditForm
      return isCreateEditForm
    },
    formTabs () {
      const formGroups = this.formData?.groups
      const formTabs = this.isViewModel ? formGroups : cloneDeep(formGroups)
      let manipulatedTabs = formGroups
      if (!this.isCreateEditForm) {
        manipulatedTabs = this.removeHiddenField(formTabs)
      }
      return manipulatedTabs
    },
    isFormHasNoField () {
      let isFormHasNoField = false
      this.formData.groups?.forEach(group => {
        const modelFieldsLength = group?.model_fields?.length
        if (!modelFieldsLength) {
          isFormHasNoField = true
        }
      })
      return isFormHasNoField
    },
    formIsCreateEditModelAndHasNoField () {
      return this.isCreateEditModel && this.isFormHasNoField
    }
  },
  data: () => ({
    currentPosition: 0,
    fieldToCancel: {
      index: false,
      type: false
    },
    showLogicField: {
      show: false,
      modelField: false,
      itemLogic: {}
    },
    fieldToDelete: {
      indexInGroup: false,
      model_field: false
    },
    dependentFields: [],
    dependentFieldsCondition: '',
    addFieldText: Vue.prototype.$FORM('TEXT').BUTTON.ADD_FIELD,
    addTabText: Vue.prototype.$FORM('TEXT').BUTTON.ADD_TAB,
    removeTabText: Vue.prototype.$FORM('TEXT').BUTTON.REMOVE_TAB,
    modalSetToRemove: Vue.prototype.$FORM('TEXT').WORD.TAB,
    indexOfEmptyGroup: false,
    over: {}
  }),
  methods: {
    checkPosition (index) {
      return this.currentPosition === index
    },
    changeTabPosition (index) {
      const tabTitle = this.$FORM('REFS').HEADER_EDIT
      if (!this.currentTabHeaderIsEmpty) {
        this.resetError()
        this.removeAnimation()
        this.currentPosition = index
      } else {
        this.$refs[tabTitle].enableTitleEdit() // disable tab name edit when change tab
      }
    },
    confirmDelete () {
      this.modalSetToRemove === this.$FORM('TEXT').WORD.FIELD ? this.removeField() : this.removeTab()
    },
    listOfDependentFields (fieldName) {
      const result = []
      this.formData.groups.forEach(group => {
        group.model_fields.forEach(field => {
          if (field.logic) {
            field.logic.conditions.groups.forEach(logicField => {
              logicField.groups.forEach(fieldLogicGroup => {
                if (fieldLogicGroup.model_field === fieldName) {
                  const fieldLabel = find(this.formData.model_fields, ['name', field.model_field]).label
                  result.push(fieldLabel)
                }
              })
            })
          }
        })
      })
      return result
    },
    askRemoveField (index, item) {
      if (!this.currentTabHeaderIsEmpty) {
        this.dependentFields = this.listOfDependentFields(item.model_field)
        this.modalSetToRemove = this.$FORM('TEXT').WORD.FIELD
        this.$bvModal.show('modalRemove')
        this.fieldToDelete.indexInGroup = index
        this.fieldToDelete.model_field = find(this.formData.model_fields, ['name', item.model_field])
        this.dependentFieldsCondition = this.fieldToDelete.model_field.label
      }
    },
    checkModelField (itemName) {
      // To check whether the model field already used or not
      if (!this.usedModelField.length) return false
      return this.usedModelField.includes(itemName)
    },
    addTab () {
      if (!this.currentTabHeaderIsEmpty) {
        this.formData.groups.push({
          model_fields: [],
          header: this.$FORM('TEXT').TITLE.NEW_TAB
        })
        this.currentPosition = this.tabTotal - 1
      }
    },
    askRemoveTab () {
      if (!this.currentTabHeaderIsEmpty) {
        const dependentFieldsCondition = []
        this.dependentFields = []
        this.currentTab.model_fields.forEach(field => {
          const listOfDependentFields = this.listOfDependentFields(field.model_field)
          if (listOfDependentFields.length) {
            const condition = find(this.formData.model_fields, ['name', field.model_field])
            dependentFieldsCondition.push(condition?.label)
            listOfDependentFields.forEach(dependentFields => {
              if (!this.dependentFields.includes(dependentFields)) this.dependentFields.push(dependentFields)
            })
          }
        })
        this.dependentFieldsCondition = dependentFieldsCondition.toString()
        this.modalSetToRemove = this.$FORM('TEXT').WORD.TAB
        this.$bvModal.show('modalRemove')
      }
    },
    removeTab () {
      this.resetError()
      this.currentTab.model_fields.forEach(field => this.removeDependentCondition(field.model_field))
      if (this.currentPosition === 0) {
        this.formData.groups.splice(this.currentPosition, 1)
      } else {
        this.currentPosition = this.currentPosition - 1
        this.formData.groups.splice(this.currentPosition + 1, 1)
      }
    },
    removeDependentCondition (fieldName) {
      this.formData.groups.forEach(group => {
        group.model_fields.forEach(field => {
          if (field.logic) {
            let emptyFields
            field.logic.conditions.groups.forEach(logicField => {
              logicField.groups = logicField.groups.filter(field => field.model_field !== fieldName)
              emptyFields = logicField.groups.length === 0
            })
            field.logic = emptyFields ? null : field.logic
          }
        })
      })
    },
    removeField () {
      this.modalSetToRemove = this.$FORM('TEXT').WORD.TAB
      this.formData.groups[this.currentPosition].model_fields.splice(
        this.fieldToDelete.indexInGroup,
        1
      )
      this.removeDependentCondition(this.fieldToDelete.model_field.name)
    },
    addAllRequiredField () {
      this.resetError()
      this.availableModelField.forEach(item => {
        if (item.validation.required === true) this.addField(true, item)
      })
    },
    addField (addRequiredField, data) {
      this.resetError()
      if (!isEmpty(this.modelFieldOptions) && !isEmpty(this.availableModelField)) {
        const field = addRequiredField && data ? data : this.availableModelField[0] // get default item based on available items
        this.formData.groups[this.currentPosition].model_fields.push({
          model_field: field.name,
          hidden: field.df_options.hidden,
          disabled: field.df_options.disabled,
          logic: null,
          animation: true
        })
      }
    },
    cancelHideOrDisabled () {
      this.currentTab.model_fields[this.fieldToCancel.index][this.fieldToCancel.type] = false
    },
    async showLogicModal (field) {
      if (!this.currentTabHeaderIsEmpty) {
        // filter fields based on form fields
        let validFields = []
        if (this.formData.model_fields) {
          validFields = this.formData.model_fields.filter(item => this.checkModelField(item.name))
        }
        // Pass in the field to the LogicSettings component and open the modal
        await this.$refs.LogicSettings.openMe(field, validFields)
      }
    },
    getField (fieldName) {
      if (this.formData.model_fields) {
        return this.formData.model_fields.find(field => field.name === fieldName)
      }
    },
    getDependentFields (dfOptions) {
      const dependentFields = {}
      if (dfOptions.logic) {
        dfOptions.logic.conditions.groups.forEach(group => {
          group.groups.forEach(item => {
            dependentFields[item.model_field] = this.getField(item.model_field)
          })
        })
      }
      return dependentFields
    },
    cogColour (logic) {
      // should be green if has logic else blue
      return logic ? 'success' : 'primary'
    },
    resetError () {
      this.$emit(this.$FORM('EMIT_EVENT').RESET_ERROR, true)
    },
    removeAnimation () {
      this.formData.groups.forEach(group => {
        group.model_fields.forEach(modelField => {
          delete modelField.animation
        })
      })
    },
    async validateFormData () {
      if (!this.currentTabHeaderIsEmpty) {
        let allGroupNotEmpty = true
        let requiredFilled = true
        this.indexOfEmptyGroup = false
        this.removeAnimation()
        this.formData.groups.forEach((group, index) => {
          if (group.model_fields.length === 0) {
            allGroupNotEmpty = false
            this.indexOfEmptyGroup = index
          }
        })
        this.availableModelField.forEach(item => {
          if (item.validation.required === true) requiredFilled = false
        })

        const isEmptyPermittedGroups = isEmpty(this.formData.permitted_groups) && this.isValidatePermittedGroups
        const isFormTitleEmpty = !this.formData.title
        if (!this.hasModelFields) {
          allGroupNotEmpty = true
        }

        if (allGroupNotEmpty && requiredFilled && !isEmptyPermittedGroups && !isFormTitleEmpty) {
          this.$emit(this.$FORM('EMIT_EVENT').CONFIRM_DF_VALID, true)
        } else if (!allGroupNotEmpty) {
          this.currentPosition = this.indexOfEmptyGroup
          this.setError(this.emptyTabWarn)
        } else if (isEmptyPermittedGroups) {
          this.setError(this.emptyPermittedGroupsWarn)
        } else if (isFormTitleEmpty) {
          this.setError(true)
        } else {
          this.setError(this.missingRequiredFieldWarn)
        }
      }
    },
    setError (value) {
      this.$emit(this.$FORM('EMIT_EVENT').SET_ERROR, value)
    },
    startDrag (item, event, type) {
      if (!this.isCreateEditForm) {
        return false
      }
      if (type === this.$FORM('TEXT').WORD.TAB) {
        event.dataTransfer.setDragImage(event.target, 20, 20)
      } else {
        item.animation = true
      }
    },
    onDragOver (item, pos, event) {
      if (!this.isCreateEditForm) {
        return false
      }
      this.over = { item, pos }
      event.preventDefault()
    },
    finishDrag (item, pos, type) {
      if (!this.isCreateEditForm) {
        return false
      }
      if (type === this.$FORM('TEXT').WORD.TAB) {
        this.formData.groups.splice(pos, 1)
        this.formData.groups.splice(this.over.pos, 0, item)
        this.currentPosition = this.over.pos
      } else {
        this.formData.groups[this.currentPosition].model_fields.splice(pos, 1)
        this.formData.groups[this.currentPosition].model_fields.splice(this.over.pos, 0, item)
      }
      this.over = {}
    },
    toGenericView () {
      this.$emit(this.$FORM('EMIT_EVENT').TO_GENERIC_VIEW, true)
    },
    downloadAttachment (field) {
      this.$emit(this.$FORM('EMIT_EVENT').DOWNLOAD, field)
    },
    deleteFormAttachment (fieldName, action) {
      this.$emit('deleteFormAttachment', fieldName, action)
    },
    verifyField (fieldName, fieldValue) {
      this.$emit('verifyField', fieldName, fieldValue)
    },
    removeHiddenField (formGroups) {
      const hiddenField = this.findHiddenField()
      if (formGroups) {
        formGroups.forEach(group => {
          remove(group.model_fields, field => {
            const fieldName = field.name || field.options?.name
            return hiddenField.includes(fieldName)
          }) // Remove Hidden Field
        })
        remove(formGroups, function (tab) {
          return !tab.model_fields.length
        }) // Remove Empty Tab
      }
      return formGroups || []
    },
    findHiddenField () {
      /**
       * This method is used for lists the HIDDEN fields based on Dynamic Forms logic
       * The list are used for hiding HIDDEN field on View Record page
       * This method also used in create/edit record but only for hiding tab that only have one Hidden Field
       */
      const hiddenField = []
      const effect = this.$FORM('LOGIC').PROPERTIES.HIDDEN
      const andOperator = this.$FORM('LOGIC').OPERATOR.AND // 'AND'
      const orOperator = this.$FORM('LOGIC').OPERATOR.OR // 'OR'

      if (this.formData?.groups) {
        this.formData.groups.forEach(group => {
          group.model_fields.forEach(field => {
            const fieldLogic = field?.logic || field.options?.df_options?.logic

            if (fieldLogic) {
              const isEffectHidden = fieldLogic?.case_if.find(cif => cif.target === effect)

              if (isEffectHidden) {
                fieldLogic.conditions.groups.forEach(logic => {
                  let countMatch = 0 // for AND operator use, to count matched targetFieldValue

                  logic.groups.forEach(condition => {
                    const findTargetField = find(this.modelFieldOptions, ['name', condition.model_field])

                    const targetFieldValue = findTargetField?.value || findTargetField?.options?.value
                    const isMatch = targetFieldValue === condition?.value // is target field value (in form) and condition value (in logic) is same

                    if (isMatch) {
                      const hiddenFieldName = field.name || field.model_field // field that should be hidden if isMatch
                      const logicOperator = logic?.operator
                      const isOrOperator = logicOperator === orOperator
                      const isAndOperator = logicOperator === andOperator

                      if (isOrOperator) {
                        hiddenField.push(hiddenFieldName)
                      } else if (isAndOperator) {
                        countMatch = countMatch + 1 // if 'AND' operator, should check is all of condition already fullfiled, by counting condition number

                        if (logic.groups.length === countMatch) {
                          hiddenField.push(hiddenFieldName)
                        }
                      }
                    }
                  })
                })
              }
            }
          })
        })
      }
      return hiddenField
    },
    itemOptionsExists (item) {
      if (item.options) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.field-area {
  position: relative;
  min-height: 150px;
  border: 1px dashed #91b0b3;
  padding: 0px 13px 0px 13px;
}

.empty-field-text {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 40%;
  top: 50px;
  font-size: 16px;
}

.disabledOption {
  background: #c8c8c8;
}

.remove-field {
  border: 1px solid #000000;
  padding: 10px;
}

.background-danger {
  background: #F8D7DA;
}

.my-4 {
  margin-left: 22px
}

a.nav-link {
  background: #69696969;
  color: white;
  margin: 0px 1px 2px 1px;
}

.background-animation {
  width: 100%;
  height: 100%;
  animation: pulse 1s;
}

@keyframes pulse {
  0% {
    background-color: rgb(101, 171, 247);
  }
  100% {
    background-color: #FFFFFF;
  }
}

.cursor-move {
  cursor: move;
}

.arrow-left {
  fill: grey;
  height: 15px;
  transform: rotate(-90deg);
  margin-top: -2px;
}

.small-font {
  font-size: 14px;
}

.generic-icon {
  width: 18px;
  height: auto;
  fill: white;
}
</style>
