import { render, staticRenderFns } from "./LogicSettings.vue?vue&type=template&id=2842731e&scoped=true&"
import script from "./LogicSettings.vue?vue&type=script&lang=js&"
export * from "./LogicSettings.vue?vue&type=script&lang=js&"
import style0 from "./LogicSettings.vue?vue&type=style&index=0&id=2842731e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2842731e",
  null
  
)

export default component.exports