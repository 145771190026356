import { render, staticRenderFns } from "./QueryCanvas.vue?vue&type=template&id=9a273c88&scoped=true&"
import script from "./QueryCanvas.vue?vue&type=script&lang=js&"
export * from "./QueryCanvas.vue?vue&type=script&lang=js&"
import style0 from "./QueryCanvas.vue?vue&type=style&index=0&id=9a273c88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a273c88",
  null
  
)

export default component.exports