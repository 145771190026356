import Vue from 'vue'
import ModelService from '@/modules/insight/services/model.service'
import ToastMessage from '@/utils/toast_message'

import { mapActions } from 'vuex'
import { hasAnyPermission } from '@/modules/insight/utils'

export default {
  data: () => ({
    position: 'details',
    ends: {
      end1: {
        label: '',
        icon: null,
        related: Vue.prototype.$INSIGHT('LINKEND').COMPONENTS.SOURCE
      },
      end2: {
        label: '',
        icon: null,
        related: Vue.prototype.$INSIGHT('LINKEND').COMPONENTS.TARGET
      }
    },
    verificationId: 0,
    linkendData: {},
    restrictedItems: [],
    showError: false,
    displayError: {
      code: -1,
      message: ''
    }
  }),
  computed: {
    initialised () {
      return this.hasInitialised || this.linkendParsed
    }
  },
  methods: {
    ...mapActions('insight', [
      'getUnfilteredEntities'
    ]),
    async selectedEntity (entity, entityLabel, modelName) {
      if (this.position === 'details') return

      const response = await ModelService.getIconImage(modelName)

      this.ends[this.position].label = entityLabel
      this.linkendData[this.ends[this.position].related].value = entity.unique_id
      // TODO JSON
      this.linkendData[`${this.ends[this.position].related}_type`].value = this.entityChoices()[modelName].value
      const modelLabel = this.entityChoices()[modelName].text
      this.toastSelectedEntity(entityLabel, modelLabel)
      this.resetError()
      this.ends[this.position].icon = (response.status === 200) ? window.URL.createObjectURL(new Blob([response.data])) : false

      this.verifyLink()
      // TODO Do Not swallow the error
      try {
        this.$refs['choose-linkend'].hide()
      } catch (error) {}
    },

    async verifyLink (submit = false) {
      this.verificationId += 1
      const currentId = this.verificationId
      const model = this.$route.params.model || this.formData.model

      // Setting params for advanced search based on discriminator values
      const params = {
        page: 1,
        search: null,
        // For 'edit' case where there will be one with the same ID
        perPage: 2,
        ordering: '-create_date, link_id',
        types: 'linkend',
        advancedSearch: {
          type: 'or',
          groups: [
            {
              type: 'and',
              conditions: []
            }
          ]
        }
      }

      if (this.$route.params.id) {
        params.advancedSearch.groups.forEach((group) => {
          group.conditions.push({
            field: 'link_id',
            value: this.$route.params.id,
            operator_type: 1
          })
        })
      }
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const discriminator in this.linkendData) {
        if ([undefined, null, ''].indexOf(this.linkendData[discriminator].value) !== -1) return
        params.advancedSearch.groups[0].conditions.push({
          field: discriminator,
          operator: 1,
          value: this.linkendData[discriminator].value,
          operator_type: 0
        })
      }

      const data = await ModelService.checkDiscriminators(model, params)

      if (this.verificationId === currentId && data.exists) {
        let line
        switch (this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.CONFIDENCE].value) {
          case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.CONFIRMED:
            line = '\u{2500}'
            break
          case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.UNCONFIRMED:
            line = '\u{254C}'
            break
          case this.$INSIGHT('LINKEND').CONFIDENCE_TYPES.TENTATIVE:
            line = '\u{2508}'
        }
        let direction
        switch (this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.DIRECTION].value) {
          case this.$INSIGHT('LINKEND').DIRECTION_TYPES.NONE:
            direction = line
            break
          case this.$INSIGHT('LINKEND').DIRECTION_TYPES.RIGHT:
            direction = '>'
            break
          case this.$INSIGHT('LINKEND').DIRECTION_TYPES.LEFT:
            direction = '<'
            break
          case this.$INSIGHT('LINKEND').DIRECTION_TYPES.BOTH:
            direction = '><'
        }
        const message = `${data.name ? data.name : 'Link'} ${this.$INSIGHT('ERROR').LINK_ALREADY_EXISTS}\n${this.linkendData.source.value} ${line}${line}${direction}${line}${line} ${this.linkendData.target.value}`

        // Probably should check if a DR toast already exists
        if (!submit) {
          this.$root.$bvToast.toast(message, {
            title: this.$INSIGHT('LINK_FORM_FIELD').TOAST.DUPLICATE_RECORD.TITLE,
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'warning'
          })
        }
        return message
      }
      return false
    },
    toastSelectedEntity (entityLabel, modelName) {
      ToastMessage.showSelectedDefault({
        vueInstance: this,
        prefixName: modelName,
        name: entityLabel,
        postfixText: `${this.$INSIGHT('LINK_FORM_FIELD').TOAST.SELECT_LINK.TEXT} ${this.position}`
      })
    },
    entityChoices () {
      // make sure linkendData is populated
      if (this.initialised) {
        // get the right choices based on the position
        // Can be made DRYer
        return this.position === 'end1'
          ? this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.SOURCE_TYPE]?.meta?.choices
          : this.linkendData[this.$INSIGHT('LINKEND').COMPONENTS.TARGET_TYPE]?.meta?.choices
      }
    },
    changePosition (end) {
      this.position = end
      this.resetRecords()
      this.clearError()
      this.$refs['choose-linkend'].show()
    },
    async getPermissions () {
      const success = await this.getUnfilteredEntities()
      if (!success) return

      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const [entityName, entity] of Object.entries(this.listOfAllEntities)) {
        if (!hasAnyPermission(entity, [this.$INSIGHT('PERMISSIONS').READ])) {
          this.restrictedItems.push(entityName)
        }
      }
    },
    resetError () {
      this.showError = false
      this.displayError = {
        code: -1,
        message: ''
      }
    },
    setError (code, message) {
      this.showError = true
      this.displayError = {
        code: code,
        message: message
      }
    }
  }
}
