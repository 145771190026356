<template>
  <v-group
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    @touchEnd.self="click"
    @click.self="click"
    ref="group"
    :config="{
      id: item.id,
      draggable: true,
      x: item.left,
      y: item.top,
      name: 'Entity'
    }"
  >
    <v-circle
      v-if="item.primary === true"
      :config="{
        id: item.id,
        radius: radius,
        stroke: '#F08521',
        strokeWidth: 8,
        opacity: 1
      }"
    />
    <v-circle
      :config="{
        id: item.id,
        radius: radius,
        fill: '#FFF',
        stroke: strokeColour,
        strokeWidth: 2,
        opacity: isValidEnd === true ? 1 : 0.5
      }"
    />

    <MandatoryIcon v-if="isMandatory && isCompositeForm"/>

    <v-group
      @click.self="click"
      @touchEnd.self="click"
      :config="{
        clipFunc: (ctx) => {
          ctx.arc(0, 0, this.radius - 1, 0, Math.PI * 2)
        },
        opacity: isValidEnd === true ? 1 : 0.5
      }"
    >
      <v-image
        @click.self="click"
        @touchEnd.self="click"
        :config="{
          id: item.id,
          image: image,
          align: 'center',
          offsetX: this.customIcon !== false ? this.radius : 16,
          offsetY: this.customIcon !== false ? this.radius : 16
        }"
      />
    </v-group>

    <v-text
      :key="actionKey"
      ref="text"
      :config="{
        id: item.id,
        y: 32,
        text: item.name,
        fontSize: 16,
        fontFamily: 'Roboto',
        fill: '#000',
        align: 'center',
        offsetX,
        opacity: isValidEnd === true ? 1 : 0.1
      }"
    />
  </v-group>
</template>

<script>
import ModelService from '@/modules/insight/services/model.service'
import MandatoryIcon from '@/modules/insight/components/canvas/MandatoryIcon'
import '@/dependencies/konva_dependencies'

export default {
  name: 'query-entity',
  components: {
    MandatoryIcon
  },
  props: {
    usage: {
      default: 'query'
    },
    item: {
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validEnds: {
      type: [Object, Array],
      default: () => ([])
    },
    radius: {
      type: Number,
      default: 25
    },
    stage: {},
    actionKey: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    editMode: false,
    text: undefined,
    image: null,
    customIcon: false,
    asteriskSvg: null
  }),
  computed: {
    isValidEnd () {
      if (this.disabled === true) return false
      if (this.selected === true) return true
      if (this.validEnds.length === 0) return true
      return false
    },
    offsetX () {
      if (this.text === undefined) return 0
      return this.text.getNode().width() / 2
    },
    icon () {
      if (this.customIcon !== false) return this.customIcon
      return 'data:image/svg+xml,%3Csvg width="50" height="50" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" background="%23FFFFFF"%3E%3Cg%3E%3Crect id="top-right" height="5" width="5" y="2" x="2" stroke-width="0" fill="%23000000"/%3E%3Crect id="top-left" height="5" width="5" y="2" x="13" stroke-width="0" fill="%23000000"/%3E%3Crect id="bottom-right" height="5" width="5" y="13" x="2" stroke-width="0" fill="%23000000"/%3E%3Crect id="bottom-left" height="5" width="5" y="13" x="13" stroke-width="0" fill="%23000000"/%3E%3C/g%3E%3C/svg%3E'
    },
    strokeColour () {
      const defaultColour = '#e0e0e0'
      if (this.usage === 'query') {
        return this.item.output === true ? 'red' : defaultColour
      } else {
        return this.item.confirmed === true ? 'green' : defaultColour
      }
    },
    isMandatory () {
      return this.item?.isMandatory
    },
    isCompositeForm () {
      return this.usage !== 'query'
    }
  },
  watch: {
    icon: {
      immediate: true,
      handler: function () {
        const image = new window.Image(50, 50)
        image.src = this.icon
        this.image = image
      }
    }
  },
  async mounted () {
    this.$emit('action', {
      id: this.item.id,
      position: {
        top: this.item.top - this.radius - 2,
        left: this.item.left - this.radius - 2
      }
    })

    this.text = this.$refs.text

    if (this.item && this.item.model !== '_AnyEY') this.getIconImage()
  },
  methods: {
    click (e) {
      this.$emit('selected')
      this.$emit('action', {
        id: this.item.id,
        position: {
          top: this.item.top - this.radius - 2,
          left: this.item.left - this.radius - 2
        }
      })
    },
    mouseEnter (e) {
      e.target.getStage().container().style.cursor = 'pointer'
      this.$emit('hovered')
    },
    mouseLeave (e) {
      e.target.getStage().container().style.cursor = 'default'
    },
    async getIconImage () {
      try {
        const response = await ModelService.getIconImage(this.item.model)

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          this.customIcon = url
        } else {
          this.customIcon = false
        }
      } catch (error) {
        this.customIcon = false
      }
    }
  }
}
</script>
