<template>
  <div>
    <!-- If have permitted group option -->
    <b-form-group class="d-flex justify-content-start" v-if="permittedGroupsOption.length">
      <b-form-checkbox v-model="selectAll" @change="permitAllGroups" >
        {{$FORM('TEXT').PERMITTED_GROUP.SELECT_ALL_GROUP }}
      </b-form-checkbox>
      <b-form-checkbox-group
        class="ml-2"
        :stacked="stacked"
        v-model="permittedGroups"
        :options="permittedGroupsOption"
        checkboxes
      />
    </b-form-group>
    <!-- if DONT have permitted group option -->
    <div class="d-flex flex-column justify-content-center" v-else>
      <span class="empty-permitted-group-warning">
        {{$FORM('TEXT').PERMITTED_GROUP.EMPTY_PERMITTED_GROUPS}}
      </span>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

export default {
  name: 'permitted-group',
  props: {
    parentData: {
      default: false
    },
    validPermittedGroups: {
      default: () => []
    },
    stacked: {
      default: true
    }
  },
  data: () => ({
    selectAll: true,
    permittedGroups: [],
    permittedGroupsOption: []
  }),
  methods: {
    permitAllGroups (selectAll) {
      this.permittedGroups = []
      if (selectAll) {
        this.permittedGroupsOption.forEach(option => {
          this.permittedGroups.push(option.value)
        })
      }
    },
    setPermittedGroupsOption (givenPermittedGroupsOption, selectAll) {
      if (!isEmpty(givenPermittedGroupsOption)) {
        const permittedGroupsOption = []
        for (const option in givenPermittedGroupsOption) {
          permittedGroupsOption.push({
            value: givenPermittedGroupsOption[option],
            text: option
          })
        }
        this.permittedGroupsOption = permittedGroupsOption
        if (selectAll) {
          const allOfPermittedGroups = []
          permittedGroupsOption.forEach(option => { allOfPermittedGroups.push(option.value) })
          this.permittedGroups = allOfPermittedGroups
        }
      }
    },
    checkIfSelectedAll () {
      const isSelectedAll = this.permittedGroups?.length === this.permittedGroupsOption?.length
      this.selectAll = isSelectedAll
    },
    emitPermittedGroups (value) {
      this.$emit('changePermittedGroup', value)
    }
  },
  watch: {
    permittedGroups: {
      immediate: true,
      handler: function (value) {
        this.emitPermittedGroups(value)
        this.checkIfSelectedAll()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.empty-permitted-group-warning {
  font-size: 12px;
  text-align: center;
  color: grey;
}

svg {
  width: 1em;
  height: auto;

  path {
    fill: #fff;
  }
}
</style>
