import { stringToISO } from '@/modules/insight/utils'

export default {
  methods: {
    dateString (value, type) {
      // Return null if trivial
      if (['', null, undefined].indexOf(value) !== -1) return null
      switch (type) {
        case this.$INSIGHT('INPUT_TYPES').DATE:
        case this.$INSIGHT('INPUT_TYPES').CALCULATED_DATE:
          return new Date(value).toDateString()
        case this.$INSIGHT('INPUT_TYPES').DATETIME:
          value = new Date(`${value}Z`)
          // TODO JSON Maybe can consider abstracting errors too
          return isNaN(value) ? 'Invalid Date' : value.toUTCString().slice(0, -4)
        case this.$INSIGHT('INPUT_TYPES').TIME:
          // TODO Need to accomodate both short form HH:MM
          // and long form.
          // Probably do a regex match. If match neither long or short, then just invalid
          value = new Date(stringToISO(value, type))
          return isNaN(value) ? 'Invalid Time' : value.toLocaleTimeString()
        default:
          return value
      }
    }
  }
}
