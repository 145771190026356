<template>
  <transition
      name="spin"
      tag="div"
  >
    <div
      class="actions"
      v-if="selected === true"
    >
      <div class="inner" @click.stop="$emit('close')" v-if="usage === 'query'">
        <div class="row">
          <div class="circle edit" :class="{ open: editName === true }" @click.prevent.stop="toggleEditName" v-b-tooltip:query-canvas.html="'Edit Name'">
            <div class="inside">
              <la-edit />
              <div class="popout" @click.prevent.stop v-if="editName === true && selected === true">
                <b-input :value="item.name" @input="emitName" />
              </div>
            </div>
          </div>
        <div class="circle edit" @click.prevent.stop="$emit('setConditions')" v-b-tooltip:query-canvas.html="'Filter'">
          <div class="inside">
            <la-filter />
          </div>
        </div>
          <div class="circle set-output" @click.prevent.stop="$emit(item.output === true ? 'removeOutput' : 'setOutput')" v-b-tooltip:query-canvas.html="item.output === false ? 'Set as output' : 'Remove as output'">
            <div class="inside">
              <la-file-export />
            </div>
          </div>
          <div class="circle delete" @click.prevent.stop="$emit('remove')" v-b-tooltip:query-canvas.html="'Remove Entity'">
            <div class="inside">
              <la-trash />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="circle left" @click.prevent.stop="changeDirection(2)" v-b-tooltip:query-canvas.html="'Right to Left'">
            <div class="inside">
              <la-arrow-left />
            </div>
          </div>
          <div class="circle both" @click.prevent.stop="changeDirection(3)" v-b-tooltip:query-canvas.html="'Both directions'">
            <div class="inside">
              <la-arrows-alt-h />
            </div>
          </div>
          <div class="circle left" @click.prevent.stop="changeDirection(0)" v-b-tooltip:query-canvas.html="'No direction'">
            <div class="inside">
              <la-line-h />
            </div>
          </div>
          <div class="circle right" @click.prevent.stop="changeDirection(1)" v-b-tooltip:query-canvas.html="'Left to Right'">
            <div class="inside">
              <la-arrow-right />
            </div>
          </div>
        </div>
        <b-dropdown size="sm" variant="shadow-sm outline">
          <template v-slot:button-content>
            <span v-if="item.confidence === 0">Confirmed</span>
            <span v-else-if="item.confidence === 1">Unconfirmed</span>
            <span v-else-if="item.confidence === 2">Tentative</span>
          </template>
          <b-dropdown-item @click.prevent.stop="changeConfidence(0)" :active="item.confidence === 0">Confirmed </b-dropdown-item>
          <b-dropdown-item @click.prevent.stop="changeConfidence(1)" :active="item.confidence === 1">Unconfirmed </b-dropdown-item>
          <b-dropdown-item @click.prevent.stop="changeConfidence(2)" :active="item.confidence === 2">Tentative </b-dropdown-item>

        </b-dropdown>
      </div>

      <LinkActionsForForm
        isFor="linkActions"
        v-if="usage === `form`"
        :item="item"
        @openFormModal="$emit('openFormModal')"
        @close="$emit('close')"
        @remove="$emit('remove')"
      />
    </div>
  </transition>
</template>

<script>
import LinkActionsForForm from '@/modules/insight/components/canvas/EntityActionsButton/EntityActionsForForm'
import LaTrash from '@/assets/la-trash.svg'
import LaEdit from '@/assets/la-edit.svg'
import LaFileExport from '@/assets/la-file-export.svg'
import LaFilter from '@/assets/la-filter.svg'
import LaArrowRight from '@/assets/la-arrow-right.svg'
import LaArrowLeft from '@/assets/la-arrow-left.svg'
import LaArrowsAltH from '@/assets/la-arrows-alt-h.svg'
import LaLineH from '@/assets/la-line-h.svg'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'link-actions',
  components: {
    LinkActionsForForm,
    LaTrash,
    LaEdit,
    LaFileExport,
    LaFilter,
    LaArrowRight,
    LaArrowLeft,
    LaArrowsAltH,
    LaLineH
  },
  props: {
    usage: {
      default: 'query'
    },
    item: {
      required: true
    },
    position: {
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    links: {
      type: [Object, Array],
      default: () => ([])
    },
    newLink: {
      model: '',
      name: '',
      direction: null
    }
  },
  data: () => ({
    manageLinks: false,
    editName: false,
    directions: [
      {
        value: 0,
        text: 'None'
      },
      {
        value: 1,
        text: 'Right'
      },
      {
        value: 2,
        text: 'Left'
      },
      {
        value: 3,
        text: 'Both'
      }
    ]
  }),
  computed: {
    ...mapState('insight', {
      linkTypes: 'links'
    })
  },
  watch: {
    selected () {
      this.manageLinks = false
      this.editName = false
    }
  },
  mounted () {
    this.getLinks()
  },
  methods: {
    ...mapActions('insight', ['getLinks']),
    toggleEditName () {
      this.manageLinks = false
      this.editName = !this.editName
    },
    toggleManageLinks () {
      this.manageLinks = !this.manageLinks
      this.editName = false
    },
    emitName (value) {
      this.$emit('update', {
        item: this.item,
        value: {
          name: value
        }
      })
    },
    changeDirection (value) {
      this.$emit('update', {
        item: this.item,
        value: {
          direction: value
        }
      })
    },
    changeConfidence (value) {
      this.$emit('update', {
        item: this.item,
        value: {
          confidence: value
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {opacity:0;transform:scale(0)}
  30% {opacity:0;}
}

.spin-enter-active {
  animation:0.2s spin ease-out;
}

.spin-leave-active {
  animation:0.2s spin ease-out reverse;
}

.actions {
  transform-origin: -25% -25%;
  .inner {
    // @include on-circle($item-count: 7, $item-size: 40px);
    transform-origin: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: (40px + 10px) * 4;
    height: (40px * 2) + 10px;
    position: relative;

    .row {
      position: relative;
      width: (40px + 10px) * 4;
      height: 40px;

      &:first-of-type {
        margin-bottom: 10px;
        width: (40px + 10px) * 3;
      }
    }

    .circle {
      position: absolute;
      border-radius: 100%;
      border: 2px solid #e0e0e0;
      background: rgba(#FFF, 0.9);
      transition: any 250ms ease-in-out;
      line-height: 1;
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin: 0 5px;

      @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
          top: 0;
          left: (40px + 10px) * ($i - 1);
        }
      }

      .inside {
        position: relative;
        display: flex;
        padding-top: 6px;
        padding-left: 6px;
        height: 100%;
        transition: any 250ms ease-in-out;
      }

      &.edit {
        .inside {
          padding-top: 5px;
        }
      }

      &.delete {
        .inside {
          padding-left: 5px;
        }
      }

      &.set-output {
        .inside {
          padding-left: 5px;
        }
      }

      &:hover,
      &.open {
        background: rgba(#fff, 1.0);
        border-color: #F08521;
      }

      svg {
        height: 25px;
        width: 25px;
        path {
          fill: #000;
        }
      }

      &.open {
        border-radius: 20px;
        z-index: 100;
        width: 200px;
        height: auto;
        .inside {
          justify-content: flex-start;
          padding-top: 5px;
          padding-left: 6px;
          flex-wrap: wrap;
        }
      }

      .popout {
        position: relative;
        padding: 10px 10px 10px 4px;
        width: 100%;
      }

      &.manage-links {
        &.open {
          width: 500px;
        }

        .links {
          .link {
            display: grid;
            grid-template-columns: auto auto auto 25px;
            grid-column-gap: 10px;
            align-items: center;
            line-height: 1;
            margin: 5px 0;
            padding: 5px 0;
            border-bottom: 1px solid #e0e0e0;

            .span {
              grid-column: 1 / 3;
            }

            .add-new {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              text-align: right;
              grid-column: 3 / 5;
            }
          }
        }
      }
    }
  }
}
</style>
