<template>
  <div>
    <div
      v-if="!titleEdit.enable"
      @click="enableTitleEdit"
      class="d-flex flex-row"
      :class="{'cursor-pointer' : enableFormBuilder}"
    >
      <h3>{{headerName}}</h3>
      <div class="edit-button" v-if="enableFormBuilder && headerName"></div>
    </div>
    <div v-show="titleEdit.enable">
      <b-form @submit.prevent inline class="d-flex flex-row  align-items-start">
        <label :for="headerOf" class="font-weight-bold mr-2 mt-2">{{inputLabel}} :</label>
        <div class="d-flex flex-column justify-content-start">
          <b-input
            ref="formInput"
            :id="headerOf"
            :state="titleEdit.state"
            v-model="headerName"
            @keypress="resetError"
          />
          <b-form-invalid-feedback
            :state="titleEdit.state"
            class="font-weight-bold">
            {{errorMessage}}
          </b-form-invalid-feedback>
        </div>
        <b-button variant="success" class="ml-1" @click="enableTitleEdit">{{$FORM('TEXT').BUTTON.CONFIRM_CHANGE}}</b-button>
        <b-button variant="danger" class="ml-1" @click="cancelTitleEdit">{{$FORM('TEXT').WORD.CANCEL}}</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header-edit',
  props: {
    headerOf: {
      default: 'header-edit'
    },
    theHeader: {
      default: () => ({})
    },
    formMode: {
      default: false
    },
    enableFormBuilder: {
      default: true
    },
    error: {
      default: false
    },
    type: {
      default: 'single-form'
    }
  },
  data: () => ({
    headerName: '',
    titleEdit: {
      enable: false,
      state: undefined
    },
    errorMessage: false,
    valid: true,
    previousHeaderName: ''
  }),
  mounted () {
    this.setHeader()
  },
  computed: {
    inputLabel () {
      const title = this.headerOf === this.$FORM('HEADER_TYPE').FORM ? this.$FORM('TEXT').WORD.FORM : this.$FORM('TEXT').WORD.TAB
      const name = this.$FORM('TEXT').WORD.NAME
      return `${title} ${name}`
    }
  },
  watch: {
    theHeader: {
      deep: true,
      handler () {
        this.setHeader()
      }
    },
    headerName: {
      deep: true,
      handler (value) {
        this.resetError()
        this.titleEdit.state = !value ? false : null
        this.errorMessage = !value ? `${this.inputLabel} ${this.$FORM('ERROR').EMPTY_TITLE}` : ''
        this.headerOf === this.$FORM('HEADER_TYPE').FORM
          ? (this.theHeader.title = this.headerName)
          : (this.theHeader.header = this.headerName)
        if (this.headerName === false) this.headerName = ''
      }
    },
    error: {
      immediate: true,
      handler (value) {
        if (value?.message) {
          const listsOfError = Object.keys(value.message)
          // to check whether form Title Has error
          if (listsOfError.includes(this.$FORM('ERROR').CONDITIONS_MET.TITLE) && this.headerOf !== this.$FORM('HEADER_TYPE').TAB) {
            this.enableTitleEdit()
            this.errorMessage = value.message[this.$FORM('ERROR').CONDITIONS_MET.TITLE][0]
            this.titleEdit.state = false
          }
        }
      }
    }
  },
  methods: {
    resetError (keyEvent) {
      const key = this.$FORM('SETTINGS').ENABLE_DISABLE_HEADER_EDIT_KEY
      this.titleEdit.state = undefined
      this.$emit(this.$FORM('EMIT_EVENT').RESET_ERROR, true)
      if (keyEvent?.keyCode === key) {
        this.enableTitleEdit()
      }
    },
    enableTitleEdit () {
      if (!this.headerName) {
        this.headerName = this.previousHeaderName
        this.titleEdit.state = undefined
        this.titleEdit.enable = false
        return false
      }
      if (!this.enableFormBuilder) {
        return false
      }
      if (!this.titleEdit.enable) {
        this.previousHeaderName = this.headerName.slice()
        this.setFocus()
        return true
      } else {
        if (!this.error) {
          this.titleEdit.enable = false
        }
      }
    },
    cancelTitleEdit () {
      this.headerName = this.previousHeaderName
      this.titleEdit.state = undefined
      this.titleEdit.enable = false
    },
    setFocus () {
      this.titleEdit.enable = true
      this.$nextTick(() => this.$refs.formInput.$el.focus())
    },
    setHeader () {
      this.headerName =
        this.headerOf === this.$FORM('HEADER_TYPE').FORM
          ? this.theHeader.title
          : this.theHeader.header
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  width: 1em;
  height: auto;

  path {
    fill: #fff;
  }
}
</style>
