import Vue from 'vue'
/* To use this mixin, just set _.initialData and
_.comparedData to set the data that we want to compare

Example:

this.initialData = JSON.stringify(this.dynamicFormData)
this.comparedData = 'dynamicFormData'
*/

export default {
  data: () => ({
    previousRoute: false,
    initialData: false,
    comparedData: false,
    canLeavePage: true,
    discardChangesMsg: Vue.prototype.$INSIGHT('NAVIGATION_GUARD').BEFORE_ROUTE_LEAVE.DISCARD_CHANGES.GENERIC_MESSAGE.MESSAGE,
    discardChangesTitle: Vue.prototype.$INSIGHT('NAVIGATION_GUARD').BEFORE_ROUTE_LEAVE.DISCARD_CHANGES.GENERIC_MESSAGE.TITLE
  }),
  methods: {
    async discardChanges (to, from, next) {
      const title = this.discardChangesTitle
      const bodyContent = this.discardChangesMsg
      const okTitle = this.$INSIGHT('NAVIGATION_GUARD').BEFORE_ROUTE_LEAVE.DISCARD_CHANGES.GENERIC_MESSAGE.CONFIRM_DISCARD_BUTTON

      const currentData = JSON.stringify(this.comparedData)
      const isDifferentThanInitial = this.initialData !== currentData

      if (isDifferentThanInitial && !this.canLeavePage) {
        const confirm = await this.$bvModal.msgBoxConfirm(bodyContent, {
          title: title,
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          cancelVariant: 'danger',
          okTitle: okTitle,
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: false,
          noCloseOnBackdrop: true
        })
        if (confirm) {
          next()
        }
      } else {
        next()
      }
    },
    goBack () {
      this.$router.push(this.previousRoute)
    },
    setCanLeavePage (value) {
      this.canLeavePage = value
    }
  },
  beforeRouteLeave (to, from, next) {
    this.discardChanges(to, from, next)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.previousRoute = from
    })
  }
}
