<template>
  <div>
    <b-form-group
      v-if="isNotHidden"
      class='form-group'
      :label="label"
      :label-cols="labelCols"
      :invalid-feedback="errorMessage"
      :state="valid"
      :style="{ 'font-weight': discriminatorStyle }"
      :label-for="id"
    >
    <!-- Removed all the IDs because can't find anything using it -->
    <!-- If anything breaks, default is :id=`${field.name}-list` for radio and :id=`${field.name}-name` otherwise -->
      <!-- Standard Text/Textarea -->
      <component
        v-if="[
          $INSIGHT('INPUT_TYPES').TEXT,
          $INSIGHT('INPUT_TYPES').NUMBER,
          $INSIGHT('INPUT_TYPES').DATE,
          $INSIGHT('INPUT_TYPES').DATETIME,
          $INSIGHT('INPUT_TYPES').TIME,
          $INSIGHT('INPUT_TYPES').URL,
          $INSIGHT('INPUT_TYPES').TEXTAREA
        ].indexOf(field.type) !== -1"
        v-model="inputBinding"
        :is="field.type === $INSIGHT('INPUT_TYPES').TEXTAREA ? 'b-form-textarea' : 'b-form-input'"
        :type="field.type === $INSIGHT('INPUT_TYPES').DATETIME ? 'datetime-local' : field.type"
        :state="valid"
        :formatter="field.type === $INSIGHT('INPUT_TYPES').TEXT ? format : undefined"
        :disabled="isDisabled || loading"
        :maxlength="checkMaxLength(field)"
        @blur="verifyField"
        :aria-label="computeAriaLabel"
        :required="field.validation.required ? true : false"
        :id="id"
      />

      <!-- Radio/Select -->
      <component
        v-else-if="[$INSIGHT('INPUT_TYPES').RADIO, $INSIGHT('INPUT_TYPES').SELECT].indexOf(field.type) !== -1"
        v-model="inputBinding"
        :is="field.type === $INSIGHT('INPUT_TYPES').RADIO ? 'b-form-radio-group' : 'b-form-select'"
        :options="field.meta.choices"
        :state="valid"
        :disabled="isDisabled || loading"
        @change.native="verifyField"
        :aria-label="computeAriaLabel"
        :required="field.validation.required ? true : false"
        :id="id"
      >
        <template v-if="field.type === $INSIGHT('INPUT_TYPES').SELECT" v-slot:first>
          <option v-if="field.validation.required" :value="null" disabled>-- Please select an option --</option>
          <option v-else :value="null"></option>
        </template>
      </component>

      <!-- Datalist -->
      <b-form-group v-else-if="field.type === $INSIGHT('INPUT_TYPES').DATALIST" :label-for="id">
        <b-form-input
          v-model="inputBinding"
          :list="`${field.name}-list`"
          :state="valid"
          :disabled="isDisabled || loading"
          :maxlength="checkMaxLength(field)"
          @blur="verifyField"
          :aria-label="computeAriaLabel"
          :required="field.validation.required ? true : false"
          :id="id"
        />
        <b-form-datalist :id="`${field.name}-list`" :options="field.meta.choices" @blur="verifyField"/>
      </b-form-group>

      <!-- Image/File -->
      <div v-else-if="isFileInput">
        <div class="mb-2" v-if="hasOldAttachment">
          <b-button-group size="sm">
            <b-btn variant="success" @click="downloadAttachment">
              <LaDownload class="la white-svg" />
            </b-btn>
            <b-btn class="text-center" variant="danger" @click="clearFileInput">
              <LaTrash class="la white-svg" />
            </b-btn>
          </b-button-group>
        </div>
        <div class="file-input-row" v-if="!hasOldAttachment">
          <b-form-file ref="fileInput" v-model="inputBinding" :state="valid" :accept="allowedExtensions"
            :placeholder="returnFileName(field.value)" :disabled="isDisabled || loading"
            :aria-label="computeAriaLabel"
            :required="field.validation.required ? true : false"
            :id="id"
          />
          <button v-if="hasNewAttachment" @click="clearFileInput" class="clear-btn">
            <clear class="clear-icon"/>
          </button>
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import Clear from '@/assets/window-close-solid.svg'
import ModelService from '@/modules/insight/services/model.service'
import LaDownload from '@/assets/la-download.svg'
import LaTrash from '@/assets/la-trash.svg'

export default {
  name: 'EntityFormField',
  components: {
    LaDownload,
    LaTrash,
    Clear
  },
  props: {
    field: {
      default: null
    },
    value: {
      type: [String, Number, File, null]
    },
    error: {
      type: [Object, Boolean, Error],
      default: null
    },
    dependentFields: {
      type: [Object],
      default: null
    },
    hideLabel: {
      default: false,
      type: [Boolean]
    },
    valueIsIndependent: {
      // means the value is not part of 'field' props
      // then we emit the new value as @newValue
      default: false,
      type: [Boolean]
    },
    id: {
      type: [String, null], // String for forms with labels. null for without labels
      default: null
    },
    ariaLabel: {
      type: [String, null],
      default: null
    },
    loading: {
      default: false
    },
    blacklistedFileExt: {
      type: Array,
      default: () => ([])
    },
    maxFileSize: {
      type: Object,
      default: () => ({})
    }
  },
  data: function () {
    return {
      valid: null,
      localError: null,
      conditionMetHide: false,
      conditionMetDisable: false,
      showFileInputWithAttachment: false,
      // below are specifically for dynamic forms, setted if getting static property fails
      staticPropertyValue: 'VALUE',
      staticPropertyHidden: 'HIDDEN',
      staticPropertyDisabled: 'DISABLED'
    }
  },
  computed: {
    // Input Labelling
    label () {
      if (this.hideLabel) return ''
      return `${this.field.label}${this.field.validation.required ? '*' : ''}`
    },
    // Return relevant aria labels for screen reader
    computeAriaLabel () {
      if (this.ariaLabel) { // Deal with special case in LogicSettings and ConditionTab
        return this.ariaLabel
      } else if (this.field && this.field.label) {
        // Replace substrings with more useful information for screen readers
        if (this.field.label.includes('(DD)')) return this.field.label.replace('(DD)', 'Day')
        if (this.field.label.includes('(MM)')) return this.field.label.replace('(MM)', 'Month')
        if (this.field.label.includes('(YYYY)')) return this.field.label.replace('(YYYY)', 'Year')
        if (this.field.label.includes('(FULL)')) return this.field.label.replace('(FULL)', 'Full Date')
        return this.field.label
      } else {
        return ''
      }
    },
    labelCols () {
      return this.hideLabel ? 0 : 4
    },
    discriminatorStyle () {
      return this.field.discriminator ? 'bold' : 'normal'
    },
    // Input Settings
    allowedExtensions () {
      return (this.field.type === this.$INSIGHT('INPUT_TYPES').IMAGE) ? 'image/*' : null
    },
    // Error Messaging
    errorMessage () {
      if (this.error && Object.prototype.hasOwnProperty.call(this.error.message, this.field.name)) {
        if ([this.$INSIGHT('INPUT_TYPES').DATE, this.$INSIGHT('INPUT_TYPES').DATETIME].indexOf(this.field.type) !== -1) {
          return 'Invalid Date'
        }
        return Array.isArray(this.error.message[this.field.name])
          ? this.error.message[this.field.name].toString()
          : this.error.message[this.field.name]
      }
      return this.localError
    },
    errorCode () {
      // TODO Shouldn't this be error.errorCode?
      return this.error ? this.error.code : 0
    },
    isDisabled () {
      if (this.field.df_options) { // this condition is specifically for dynamic forms
        return this.conditionMetDisable || this.field.df_options.disabled
      }
      return false
    },
    isNotHidden () {
      if (this.field.df_options) { // this condition is specifically for dynamic forms
        // should use and as we want to return false if either is false
        return !this.conditionMetHide && !this.field.df_options.hidden
      }
      return true
    },
    inputBinding: {
      get: function () {
        if (this.valueIsIndependent) return this.value
        return this.field.value
      },
      set: function (newVal) {
        if (this.valueIsIndependent) this.$emit('newValue', newVal)
        this.field.value = newVal
      }
    },
    isFileInput () {
      /**
       * Returns a boolean value that indicates if the input field is a file input
       */
      return [this.$INSIGHT('INPUT_TYPES').IMAGE, this.$INSIGHT('INPUT_TYPES').FILE].indexOf(this.field.type) !== -1
    },
    hasOldAttachment () {
      /**
       * Returns a boolean value that indicates if the file input has an OLD ATTACHMENT
       */
      return this.isFileInput && this.value === this.$INSIGHT('SPECIAL_VALUES').DOWNLOAD
    },
    hasNewAttachment () {
      /**
       * Returns a boolean value that indicates if the file input has a NEW ATTACHMENT
       */
      return this.isFileInput && this.inputBinding !== null
    }
  },
  methods: {
    clearFileInput () {
      this.inputBinding = null
    },
    format (value) {
      switch (this.field.validation.format) {
        case this.$INSIGHT('VALIDATION_FORMAT').UPPER_CASE:
          return value.toUpperCase()
        case this.$INSIGHT('VALIDATION_FORMAT').UPPER_FIRST:
          return value.charAt(0).toUpperCase() + value.slice(1)
        case this.$INSIGHT('VALIDATION_FORMAT').LOWER_CASE:
          return value.toLowerCase()
        default:
          return value
      }
    },
    async downloadAttachment () {
      try {
        const response = await ModelService.getAttachment(this.$route.params.model, this.$route.params.id, this.field.name)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', response.headers['content-disposition'].match(/filename="(.*?)"/)[1])
        document.body.appendChild(link)
        link.click()
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(url)
        }, 100)
        link.remove()
      } catch (error) {
        this.$bvToast.toast(error.message, {
          title: `${this.$INSIGHT('ENTITY_FORM_FIELD').ERROR.DOWNLOAD_FAILED} ${error.errorCode}`,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        })
      }
    },
    verifyField (event) {
      if (this.field.discriminator) {
        // Set value only on change, if not mismatch
        const eventValue = event && event.type === 'change' ? event.target.value : null
        const fieldValue = eventValue || this.field.value
        this.$emit('verifyField', this.field.name, fieldValue)
      }
    },
    returnFileName (file) {
      if (file) {
        return file.name
      }
      return this.$INSIGHT('ENTITY_FORM_FIELD').SELECT_FILE.PLACEHOLDER
    },
    checkLogic () {
      const fieldLogic = this.field.df_options?.logic
      const dependentFields = this.dependentFields
      if (fieldLogic && dependentFields) {
        const operator = fieldLogic.conditions.groups[0].operator
        const andFulFilled = fieldLogic.conditions.groups[0].groups.length
        let countAndValue = 0
        let countAndHidden = 0
        let countAndDisabled = 0
        fieldLogic.conditions.groups.forEach(group => {
          let ORFulfilled = false // When one of the condition with OR operator are fulfilled, dont apply the effect again with another logic
          group.groups.forEach(async item => {
            if (item.model_field in dependentFields) {
              const match = dependentFields[item.model_field].value === item.value
              const effectValue = fieldLogic.case_if.find(cif => cif.target === this.staticPropertyValue)
              const effectHidden = fieldLogic.case_if.find(cif => cif.target === this.staticPropertyHidden)
              const effectDisabled = fieldLogic.case_if.find(cif => cif.target === this.staticPropertyDisabled)
              if (operator === 'OR') {
                if (effectValue && match) {
                  this.field.value = effectValue.value
                  ORFulfilled = true
                }
                if (effectHidden) {
                  const wasMatch = (this.conditionMetHide === effectHidden.value)
                  if (match) {
                    this.conditionMetHide = effectHidden.value
                    ORFulfilled = true
                  } else if (!match && wasMatch && !ORFulfilled) {
                    this.conditionMetHide = false
                  }
                }
                if (effectDisabled) {
                  const wasMatch = (this.conditionMetDisable === effectDisabled.value)
                  if (match) {
                    this.conditionMetDisable = effectDisabled.value
                    ORFulfilled = true
                  } else if (!match && wasMatch && !ORFulfilled) {
                    this.conditionMetDisable = false
                  }
                }
              } else if (operator === 'AND') {
                if (effectValue) {
                  if (match) countAndValue = countAndValue + 1
                  if (countAndValue === andFulFilled) this.field.value = effectValue.value
                }
                if (effectHidden) {
                  const wasMatch = (this.conditionMetHide === effectHidden.value)
                  if (match) countAndHidden = countAndHidden + 1
                  if (countAndHidden === andFulFilled) this.conditionMetHide = effectHidden.value
                  else if (!match && wasMatch) this.conditionMetHide = false
                }
                if (effectDisabled) {
                  const wasMatch = (this.conditionMetDisable === effectDisabled.value)
                  if (match) countAndDisabled = countAndDisabled + 1
                  if (countAndDisabled === andFulFilled) this.conditionMetDisable = effectDisabled.value
                  else if (!match && wasMatch) this.conditionMetDisable = false
                }
              }
            }
          })
        })
      } else { // should set states back to false for else as the component is recycled
        this.conditionMetHide = false
        this.conditionMetDisable = false
      }
    },
    checkMaxLength (field) {
      return field.validation.max_length ? field.validation.max_length : null
    },
    initialCheckLogic () {
      if (!this.dependentFields) return false
      this.checkLogic(this.dependentFields)
    },
    setStaticProperties () {
      if (!this.$FORM('LOGIC').PROPERTIES) return false
      this.staticPropertyValue = this.$FORM('LOGIC').PROPERTIES.VALUE
      this.staticPropertyHidden = this.$FORM('LOGIC').PROPERTIES.HIDDEN
      this.staticPropertyDisabled = this.$FORM('LOGIC').PROPERTIES.DISABLED
    }
  },
  mounted () {
    this.setStaticProperties()
    this.initialCheckLogic()
  },
  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.valid = null
          const errorList = []
          this.localError = null
          // Validate the dangerous filetype by the extension
          const extension = val.name && val.name.split('.').pop().toLowerCase()
          if (this.blacklistedFileExt.includes(extension)) {
            errorList.push(this.$INSIGHT('ENTITY_FORM_FIELD').ERROR.FILE_NOT_PERMITTED)
            this.valid = false
          }

          // image type validation
          if (this.field.type === this.$INSIGHT('INPUT_TYPES').IMAGE && val.type && !val.type.includes('image')) {
            errorList.push(this.$INSIGHT('ENTITY_FORM_FIELD').ERROR.INVALID_IMAGE)
            this.valid = false
          }

          // Validate the upload file size
          if (val.size > this.maxFileSize.MAX_FILE_SIZE_IN_BYTE) {
            errorList.push(this.$INSIGHT('ENTITY_FORM_FIELD').ERROR.EXCEED_FILE_SIZE_LIMIT.replace('MAX_FILE_SIZE', this.maxFileSize.MAX_FILE_SIZE_IN_MEGABYTE))
            this.valid = false
          }

          if (this.valid === false) {
            this.$refs.fileInput.reset()
            this.localError = errorList.join(', ')
          }
        } else if (val === '' && [this.$INSIGHT('INPUT_TYPES').DATE, this.$INSIGHT('INPUT_TYPES').DATETIME].indexOf(this.field.type) !== -1) {
          this.valid = false
        }
      }
    },
    errorCode: {
      immediate: true,
      handler: function (val) {
        this.valid = val === 400 && this.errorMessage ? false : null
      }
    },
    dependentFields: function (val) {
      this.checkLogic(val)
    },
    isDisabled: function (val) {
      if (val === true) {
        this.field.value = null
      }
    },
    isNotHidden: function (val) {
      if (val === false) { // should check on false as NotHidden false means hidden
        this.field.value = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.file-input-row {
  display: flex;

  .clear-btn {
    border: none;
    background-color: inherit;

    .clear-icon {
      height: 10px;
    }
  }
}
</style>
