<template>
    <div id="condition-tab">
        <b-card no-body>
            <div class="d-flex flex-row justify-content-start operator-section">
                <b-form-radio-group
                    v-if="conditionIsMoreThanOne"
                    v-model="conditionAndOr"
                    :options="conditionAndOrChoices"
                    button-variant="on-off"
                    buttons
                    size="sm"
                    name="radios-btn-default" />
            </div>
            <b-tabs small card v-model="currentPosition">
                <b-tab v-for="(item, index) in conditions" :key="index">
                    <template v-slot:title>
                        {{`${$FORM('CONDITION').TEXT.CONDITION} ${index + 1}`}}
                        <WindowClosed class="window-closed" @click="deleteThisCondition(index)" v-if="conditions.length > 1" />
                    </template>
                    <div>
                        <b-form-group>
                            <b-form-select v-model="item.modelField" @change="resetTargetValue">
                            <option
                                v-for="(item, index) of conditionOptions"
                                :key="index"
                                :value="item.value"
                                :disabled="targetUsed(item.value)"
                                :class="{disabledOption : targetUsed(item.value)}">
                                {{item.text}}
                            </option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group :label="$FORM('CONDITION').TEXT.IS" v-if="item.modelField">
                            <b-form-radio-group
                            v-model="item.value"
                            :options="targetValueChoices"
                            button-variant="outline-primary"
                            buttons
                            name="radios-btn-default"
                            ></b-form-radio-group>
                        </b-form-group>
                        <div v-if="item.value === $FORM('LOGIC').PROPERTIES.VALUE" class="d-flex flex-column">
                            <span>{{ $FORM('CONDITION').TEXT.THIS_VALUE }}</span>
                            <EntityFormField
                              @newValue="setTargetCustomValue"
                              :value="item.stringValue"
                              :field="targetFieldOptions(item.modelField)"
                              hideLabel
                              valueIsIndependent
                              ariaLabel="This Value:"/>
                        </div>
                    </div>
                </b-tab>
            <template v-slot:tabs-end>
                <b-nav-item v-if="isConditionsLessThanWithConditionOptions" @click="addCondition" v-b-tooltip:condition-tab.hover :title="$FORM('CONDITION').TEXT.ADD_MORE_CONDITION"><strong>+</strong></b-nav-item>
            </template>
            </b-tabs>
        </b-card>
        <!-- TODO : are the delete function still need a modal? -->
        <!-- <b-modal @ok="deleteThisCondition" id="deleteCondition" title="Delete Condition?">
            <p class="my-4">Are You Sure do you want to delete this Condition?</p>
        </b-modal> -->
    </div>
</template>

<script>
import Vue from 'vue'
import WindowClosed from '@/assets/window-close-solid.svg'
import EntityFormField from '@/modules/insight/components/EntityFormField'
import { find } from 'lodash'

export default {
  name: 'condition-tab',
  components: {
    EntityFormField, WindowClosed
  },
  computed: {
    conditionIsMoreThanOne () {
      if (!this.conditions) return false
      return this.conditions.length > 1
    },
    conditionOptions () {
      // to filter what model field is shown in options according to logic below
      const options = []
      const notAllowedType = [this.$INSIGHT('INPUT_TYPES').FILE, this.$INSIGHT('INPUT_TYPES').IMAGE]
      if (!this.modelFieldOptions) return []
      this.modelFieldOptions.forEach(item => {
        if ((item.name !== this.fieldName) && !notAllowedType.includes(item.type)) {
          options.push({ text: item.label, value: item.name })
        }
      })
      return options
    },
    usedOptions () {
      // to populate what model field that has been used in conditions/target
      const usedOptions = []
      if (!this.conditions.length) return []
      this.conditions.forEach(item => {
        usedOptions.push(item.modelField)
      })
      return usedOptions
    },
    targetValueChoices () {
      const choices = [
        { text: this.$FORM('CONDITION').TEXT.YES, value: -1, disabled: true },
        { text: this.$FORM('CONDITION').TEXT.NO, value: 0, disabled: true },
        { text: this.$FORM('CONDITION').TEXT.EQUAL_TO, value: this.$FORM('LOGIC').PROPERTIES.VALUE, disabled: false }
      ]
      const currentCondition = this.conditions[this.currentPosition]
      const modelField = currentCondition?.modelField
      const target = find(this.modelFieldOptions, ['name', modelField])
      if (!target?.type) return choices
      const isRadio = target.type === this.$INSIGHT('INPUT_TYPES').RADIO // if radio = true
      choices.forEach(item => {
        item.disabled = item.value === this.$FORM('LOGIC').PROPERTIES.VALUE ? isRadio : !isRadio
      })
      return choices
    },
    isConditionsLessThanWithConditionOptions () {
      return this.conditions.length < this.conditionOptions.length
    }
  },
  data: () => ({
    currentPosition: 0,
    conditionAndOr: Vue.prototype.$FORM('LOGIC').OPERATOR.OR,
    conditionAndOrChoices: [
      { text: Vue.prototype.$FORM('CONDITION').TEXT.AND, value: Vue.prototype.$FORM('LOGIC').OPERATOR.AND },
      { text: Vue.prototype.$FORM('CONDITION').TEXT.OR, value: Vue.prototype.$FORM('LOGIC').OPERATOR.OR }
    ]
  }),
  props: {
    fieldName: {
      default: false
    },
    conditions: {
      default: false
    },
    modelFieldOptions: {
      default: false
    },
    conditionOperator: {
      default: () => (Vue.prototype.$FORM('LOGIC').OPERATOR.OR)
    }
  },
  mounted () {
    this.conditionAndOr = this.conditionOperator
  },
  methods: {
    openDeleteConditionModal (index) {
      // TODO : are delete condition still need a delete modal?
      this.currentPosition = index
      this.$bvModal.show('deleteCondition')
    },
    targetFieldOptions (modelField) {
      if (!modelField) return {}
      const options = find(this.modelFieldOptions, ['name', modelField])
      return options
    },
    targetUsed (item) {
      return this.usedOptions.includes(item)
    },
    addCondition () {
      this.conditions.push({
        modelField: null,
        value: null,
        stringValue: null
      })
    },
    setTargetCustomValue (value) {
      this.conditions[this.currentPosition].stringValue = value
    },
    deleteThisCondition (index) {
      this.conditions.splice(index, 1)
      if (this.currentPosition) this.currentPosition = this.currentPosition - 1
    },
    checkForEachCondition () {
      // Check which condition is empty
      if (!this.conditions.length) return false
      this.conditions.forEach((condition, index) => {
        if (!condition.modelField || !condition.stringValue) this.currentPosition = index
      })
    },
    resetTargetValue () {
      this.conditions[this.currentPosition].value = null
    }
  },
  watch: {
    conditionAndOr: {
      immediate: true,
      handler (val) {
        this.$emit(this.$FORM('EMIT_EVENT').CONDITION_AND_OR, val)
      }
    },
    conditions: {
      immediate: true,
      deep: true,
      handler (conditions) {
        if (!conditions) return false
        if (conditions.length === 1) this.conditionAndOr = this.$FORM('LOGIC').OPERATOR.OR
      }
    }
  }
}
</script>

<style lang="scss">
.operator-section {
    padding: 4px;
}

.window-closed {
  margin: 0px 0px 2px 4px;
  padding: 2px;
  height: 12px;
  cursor: initial;
  fill: #000000c7;

  &:hover {
    background: grey;
    border-radius: 10px;
    fill: white;
  }
}

.disabledOption {
  background: #c8c8c8;
}
</style>
