<template>
  <div class="link-icon" @click.prevent="$emit('click')">
    <div class="icon">
      <icon-link />
    </div>
  </div>
</template>

<script>
import IconLink from '@/assets/link.svg'

export default {
  name: 'entity-icon',
  components: {
    IconLink
  }
}
</script>

<style lang="scss" scoped>
.link-icon {
  flex-grow: 0;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  /deep/ svg {
    transition: all 150ms ease-in-out;
    width: 65%;
    height: 65%;
  }

  .icon {
    border: 1px solid #e0e0e0;
    border-radius: 100%;
    overflow: hidden;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
  }

  &:hover {
    /deep/ svg {
      fill: #F08521;
    }
  }
}
</style>
