<template>
  <v-group
    ref="group"
    :config="{
      id: id
    }"
  >
    <v-line
      :config="{
        dash: confidenceStrength,
        stroke: strokeColour,
        id,
        fill,
        points: [x1, y1, x2, y2],
        strokeWidth: 3,
        lineCap: 'bevel',
        lineJoin: 'bevel',
      }"
    />
    <v-line
      v-if="direction === 2 || direction === 3"
      :config="{
        id,
        x: leftArrowX,
        y: leftArrowY,
        points: leftArrowPoints,
        fill: '#FFF',
        stroke: '#000',
        strokeWidth: 2,
        closed: true,
        rotation: leftArrowRotate
      }"
    />

    <v-line
      v-if="direction === 1 || direction === 3"
      :config="{
        id,
        x: rightArrowX,
        y: rightArrowY,
        points: rightArrowPoints,
        fill: '#FFF',
        stroke: '#000',
        strokeWidth: 2,
        closed: true,
        rotation: rightArrowRotate
      }"
    />

    <v-text
      @mouseenter="mouseEnter"
      @mouseleave="mouseLeave"
      @click.self="click"
      @touchEnd.self="click"
      ref="text"
      :config="{
        id: id,
        x: textX,
        y: textY,
        text: name,
        fontSize: 16,
        fontFamily: 'Roboto',
        fill: '#000',
        offsetX: offsetX,
        offsetY: offsetY,
        rotation: rotateText
      }"
    />
  </v-group>
</template>

<script>
import { debounce } from 'lodash'

import '@/dependencies/konva_dependencies'

export default {
  name: 'query-link',
  props: {
    usage: {
      default: 'query'
    },
    id: {
      required: true
    },
    name: {
      required: true
    },
    end1: {
      required: true
    },
    end2: {
      required: true
    },
    selected: {
      default: false
    },
    disabled: {
      default: false
    },
    output: {
      default: false
    },
    fill: {
      default: '#000'
    },
    direction: {
      default: 0
    },
    confidence: {
      default: 0
    },
    radius: {
      default: 25
    },
    linkConfirmed: {
      default: false
    }
  },
  data: () => ({
    text: undefined,
    leftArrow: []
  }),
  computed: {
    angle () {
      const to = this.end2
      const from = this.end1
      const dx = to.left - from.left
      const dy = to.top - from.top
      const angle = Math.atan2(-dy, dx)
      return angle
    },
    x1 () {
      return this.end1.left + -this.radius * Math.cos(this.angle + Math.PI)
    },
    y1 () {
      return this.end1.top + this.radius * Math.sin(this.angle + Math.PI)
    },
    x2 () {
      return this.end2.left + -this.radius * Math.cos(this.angle)
    },
    y2 () {
      return this.end2.top + this.radius * Math.sin(this.angle)
    },
    textY () {
      return (this.y2 + this.y1) / 2
    },
    textX () {
      return (this.x2 + this.x1) / 2
    },
    actionPosition () {
      return {
        top: this.textY,
        left: this.textX
      }
    },
    rotateText () {
      const angle = Math.asin(this.opposite / this.hypotenuse) * (180 / Math.PI)
      if (this.y1 > this.y2) {
        if (this.x1 > this.x2) return angle
        return 360 - angle
      }

      if (this.x1 > this.x2) return 0 - angle
      return angle
    },
    hypotenuse () {
      return Math.hypot(this.opposite, this.adjacent)
    },
    opposite () {
      return Math.abs(this.y1 - this.y2)
    },
    adjacent () {
      return Math.abs(this.x1 - this.x2)
    },
    offsetX () {
      if (this.text === undefined) return 0
      return this.text.getNode().width() / 2
    },
    offsetY () {
      if (this.y1 > this.y2) {
        if (this.x1 > this.x2) return 25
      }

      return 25
    },
    leftArrowX () {
      return this.x1
    },
    leftArrowY () {
      return this.y1
    },
    leftArrowRotate () {
      const angle = Math.asin(this.opposite / this.hypotenuse) * (180 / Math.PI)
      if (this.y1 > this.y2) {
        if (this.x1 > this.x2) return angle
        return 360 - angle
      }

      if (this.x1 > this.x2) return 0 - angle
      return angle
    },
    leftArrowPoints () {
      const points = [
        5, 0,
        19, 7,
        19, -7
      ]

      if (this.x1 > this.x2) {
        return [
          -5, 0,
          -19, 7,
          -19, -7
        ]
      }

      return points
    },
    rightArrowX () {
      return this.x2
    },
    rightArrowY () {
      return this.y2
    },
    rightArrowRotate () {
      const angle = Math.asin(this.opposite / this.hypotenuse) * (180 / Math.PI)
      if (this.y1 > this.y2) {
        if (this.x1 > this.x2) return angle
        return 360 - angle
      }

      if (this.x1 > this.x2) return 0 - angle
      return angle
    },
    rightArrowPoints () {
      const points = [
        -5, 0,
        -19, 7,
        -19, -7
      ]

      if (this.x1 > this.x2) {
        return [
          5, 0,
          19, 7,
          19, -7
        ]
      }

      return points
    },
    confidenceStrength () {
      if (this.confidence === 1) return [25, 35]
      else if (this.confidence === 2) return [10, 10]
      else return [0]
    },
    strokeColour () {
      if (this.usage === 'query') {
        return this.output === true ? 'red' : this.fill
      } else {
        return this.linkConfirmed === true ? 'green' : this.fill
      }
    }
  },
  async mounted () {
    this.text = this.$refs.text
  },
  watch: {
    actionPosition () {
      if (this.selected === true) {
        this.$emit('action', {
          rotate: this.rotateText,
          position: this.actionPosition
        })
      }
    }
  },
  methods: {
    click: debounce(function (e) {
      this.$emit('selected')
      this.$emit('action', {
        rotate: this.rotateText,
        position: this.actionPosition
      })
    }, 100),
    mouseEnter (e) {
      e.target.getStage().container().style.cursor = 'pointer'
    },
    mouseLeave (e) {
      e.target.getStage().container().style.cursor = 'default'
    }
  }
}
</script>
