var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-group',{ref:"group",attrs:{"config":{
    id: _vm.item.id,
    draggable: true,
    x: _vm.item.left,
    y: _vm.item.top,
    name: 'Entity'
  }},on:{"mouseenter":_vm.mouseEnter,"mouseleave":_vm.mouseLeave,"touchEnd":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.click($event)},"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.click($event)}}},[(_vm.item.primary === true)?_c('v-circle',{attrs:{"config":{
      id: _vm.item.id,
      radius: _vm.radius,
      stroke: '#F08521',
      strokeWidth: 8,
      opacity: 1
    }}}):_vm._e(),_c('v-circle',{attrs:{"config":{
      id: _vm.item.id,
      radius: _vm.radius,
      fill: '#FFF',
      stroke: _vm.strokeColour,
      strokeWidth: 2,
      opacity: _vm.isValidEnd === true ? 1 : 0.5
    }}}),(_vm.isMandatory && _vm.isCompositeForm)?_c('MandatoryIcon'):_vm._e(),_c('v-group',{attrs:{"config":{
      clipFunc: function (ctx) {
        ctx.arc(0, 0, this$1.radius - 1, 0, Math.PI * 2)
      },
      opacity: _vm.isValidEnd === true ? 1 : 0.5
    }},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.click($event)},"touchEnd":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.click($event)}}},[_c('v-image',{attrs:{"config":{
        id: _vm.item.id,
        image: _vm.image,
        align: 'center',
        offsetX: this.customIcon !== false ? this.radius : 16,
        offsetY: this.customIcon !== false ? this.radius : 16
      }},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.click($event)},"touchEnd":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.click($event)}}})],1),_c('v-text',{key:_vm.actionKey,ref:"text",attrs:{"config":{
      id: _vm.item.id,
      y: 32,
      text: _vm.item.name,
      fontSize: 16,
      fontFamily: 'Roboto',
      fill: '#000',
      align: 'center',
      offsetX: _vm.offsetX,
      opacity: _vm.isValidEnd === true ? 1 : 0.1
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }