var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-group',[_c('v-circle',{attrs:{"config":{
      radius: 50,
      fill: 'black',
      opacity: 1,
      width: 18,
      height: 18,
      x: 22,
      y: -22
    }}}),_c('v-text',{attrs:{"config":{
    text: '*',
    fontFamily: 'Arial',
    fill: 'white',
    fontSize: 29,
    x: 16.5,
    y: -28
   }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }