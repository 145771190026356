<template>
  <b-modal
    :title="titleName"
    :id="fieldName"
    @ok="submitSettings"
    okVariant="success"
    cancel-variant="danger"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    size="lg"
    @cancel="cancelSettings"
  >
    <div class="d-flex flex-column">
      <span class="mb-1">{{ $FORM('LOGIC').TEXT.TO }}</span>
      <span class="d-flex flex-row justify-content-start">
        <b-button-group id="button-tooltip" class="mb-2">
          <b-button
            @click="effectChoicesChange(item)"
            v-for="(item, index) in effectChoices"
            :id="item.ref"
            :key="index"
            :pressed.sync="item.state"
            :disabled="item.disabled"
            variant="outline-primary">
            <span :id="`${item.ref}-span`" :tabindex="item.disabled && item.ref==='setValueButton' ? 0 : ''">{{ item.text }}</span>
            <b-tooltip v-if="effectTooltip(item.ref).enable" :target='effectTooltip(item.ref).target' :title="effectTooltip(item.ref).text" />
          </b-button>

        </b-button-group>
      </span>
      <div v-if="effectIsString" class="d-flex flex-column">
        <span>{{ $FORM('LOGIC').TEXT.TO_VALUE }}</span>
        <EntityFormField
          @newValue="setEffectCustomValue"
          :value="effectValueString"
          :field="fieldOptions"
          hideLabel
          valueIsIndependent
          ariaLabel="To This Value:"
          />
      </div>
      <label>{{ $FORM('LOGIC').TEXT.IF }} </label>
      <ConditionTab
        v-if="conditions.length"
        :ref="$FORM('REFS').CONDITION_TAB"
        :fieldName="fieldName"
        :conditions="conditions"
        :modelFieldOptions="modelFieldOptions"
        :conditionOperator="conditionAndOr"
        @conditionAndOr="assignOperator"/>
    </div>

    <b-alert variant="danger" :show="error !== false" class="mt-2 text-center d-flex flex-column">
      {{errorMessage}}
    </b-alert>

    <div class="d-flex justify-content-end mt-4">
      <b-button
        variant="info"
        @click="clearLogic"
      > {{ $FORM('LOGIC').BUTTON.CLEAR_LOGIC }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import EntityFormField from '@/modules/insight/components/EntityFormField'
import ConditionTab from '@/modules/forms/components/LogicComponents/ConditionTab'
import { find } from 'lodash'

export default {
  name: 'logic-settings',
  components: {
    EntityFormField, ConditionTab
  },
  computed: {
    fieldOptions () {
      if (!this.field.model_field) return {}
      const field = find(this.modelFieldOptions, ['name', this.field.model_field])
      return field
    },
    titleName () {
      return `${this.fieldOptions?.label} ${this.$FORM('LOGIC').TEXT.LOGIC_SETTINGS}`
    },
    fieldName () {
      if (this.field.model_field !== undefined) {
        return this.field.model_field
      }
      return ''
    },
    effectIsString () {
      const isString = find(this.effectChoices, ['value', this.$FORM('LOGIC').PROPERTIES.VALUE])
      return isString.state
    },
    fieldIsRequired () {
      if (!this.fieldOptions) return false
      const isRequired = this.fieldOptions?.validation?.required
      return isRequired
    },
    fieldIsBinary () {
      if (!this.fieldOptions) return false
      const binaryType = [this.$INSIGHT('INPUT_TYPES').IMAGE, this.$INSIGHT('INPUT_TYPES').FILE]
      const isBinary = binaryType.includes(this.fieldOptions.type)
      return isBinary
    }
  },
  data: () => ({
    conditionAndOr: Vue.prototype.$FORM('LOGIC').OPERATOR.OR,
    effectChoices: [
      { ref: 'hideButton', text: Vue.prototype.$FORM('LOGIC').TEXT.HIDE, value: Vue.prototype.$FORM('LOGIC').PROPERTIES.HIDDEN, state: false, disabled: false },
      { ref: 'disableButton', text: Vue.prototype.$FORM('LOGIC').TEXT.DISABLE, value: Vue.prototype.$FORM('LOGIC').PROPERTIES.DISABLED, state: false, disabled: false },
      { ref: 'setValueButton', text: Vue.prototype.$FORM('LOGIC').TEXT.SET_VALUE, value: Vue.prototype.$FORM('LOGIC').PROPERTIES.VALUE, state: false, disabled: false }
    ],
    error: false,
    errorMessage: false,
    effectValueString: '',
    conditions: [{
      modelField: null,
      value: null,
      stringValue: null
    }],
    currentPosition: 0,
    field: {},
    modelFieldOptions: [],
    emptyFieldErrorMsg: Vue.prototype.$FORM('LOGIC').ERROR.EMPTY_FIELD_ERROR
  }),
  methods: {
    async openMe (field, modelFieldOptions) {
      // Set up the logic data for the selected field
      this.field = field
      this.modelFieldOptions = modelFieldOptions

      await this.restore()
      this.$bvModal.show(field.model_field)
    },
    async restore () {
      await this.clearLogic()
      // if field logic is present, then restore the data
      if (this.field?.logic) {
        const logic = this.field.logic
        const conditionGroup = logic.conditions.groups[0].groups
        const conditionAndOr = logic.conditions.groups[0].operator
        const caseIf = logic.case_if

        await this.conditions.splice(0, this.conditions.length) // set this.conditions to empty first before populate the data
        // populate all data from field.logic.conditions
        await conditionGroup.forEach(item => {
          this.conditions.push({
            modelField: item.model_field,
            value: [0, -1].includes(item.value) ? item.value : this.$FORM('LOGIC').PROPERTIES.VALUE,
            stringValue: [0, -1].includes(item.value) ? null : item.value
          })
        })
        // populate all data from field.logic.case_if
        await caseIf.forEach(item => {
          const effect = find(this.effectChoices, ['value', item.target])
          if (effect) effect.state = true
          if (item.target === this.$FORM('LOGIC').PROPERTIES.VALUE) this.effectValueString = item.value
          const thisItem = { value: item.target, state: true }
          this.effectChoicesChange(thisItem)
        })
        this.conditionAndOr = conditionAndOr
      }
    },
    submitSettings (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      const conditions = []
      const caseIf = []
      const caseElse = []
      let valid = true

      // Populate Conditions into the conditions Array, check if condition is valid
      this.conditions.forEach(condition => {
        const isValue = condition.modelField && (condition.stringValue && condition.value === this.$FORM('LOGIC').PROPERTIES.VALUE)
        const isRadio = condition.modelField && ([0, -1].includes(condition.value))
        if (isValue || isRadio) {
          conditions.push({
            value: condition.value === this.$FORM('LOGIC').PROPERTIES.VALUE ? condition.stringValue : condition.value,
            model_field: condition.modelField
          })
        } else if (condition.modelField || condition.stringValue || condition.value) valid = false
      })
      if (conditions.length === 1) this.conditionAndOr = this.$FORM('LOGIC').OPERATOR.OR

      // Populate effectChoices into caseIf Array, check if state was setted = true
      this.effectChoices.forEach(effect => {
        if (effect.state) {
          const isValue = effect.value === this.$FORM('LOGIC').PROPERTIES.VALUE
          if (isValue && !this.effectValueString) valid = false
          if (this.fieldIsRequired && !this.effectValueString) valid = false
          caseIf.push({ action: this.$FORM('LOGIC').ACTION.SET, target: effect.value, value: isValue ? this.effectValueString : true })
          caseElse.push({ action: this.$FORM('LOGIC').ACTION.SET, target: effect.value, value: isValue ? '' : false })
        }
      })

      if (!caseIf.length && !conditions.length && valid) {
        // Validation 1 = if user just open the modal and do nothing
        this.field.logic = null
      } else if (!caseIf.length || !conditions.length || !valid) {
        // Validation 2 = if user change something and uncomplete, return false
        this.error = true
        if (this.fieldIsRequired) this.enableSetValueEffect()
        this.errorMessage = this.emptyFieldErrorMsg
        this.$refs.conditionTab.checkForEachCondition()
        return false
      } else {
        // Validation 3 = if changes are valid
        this.field.logic = {
          conditions: {
            groups: [{ groups: conditions, operator: this.conditionAndOr }],
            operator: this.$FORM('LOGIC').OPERATOR.OR
          },
          case_if: caseIf,
          case_else: caseElse
        }
      }
      this.$nextTick(() => {
        this.$bvModal.hide(this.fieldName)
        this.error = false
        this.errorMessage = false
      })
    },
    async clearLogic () {
      const defaultCondition = [{
        modelField: null,
        value: null,
        stringValue: null
      }]
      this.conditions = defaultCondition
      this.currentPosition = 0
      this.effectValueString = null
      this.conditionAndOr = this.$FORM('LOGIC').OPERATOR.OR
      await this.disableEffectChoices()
      await this.clearError()
    },
    cancelSettings (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.restore()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide(this.fieldName)
      })
    },
    clearError () {
      this.error = false
      this.errorMessage = false
    },
    disableEffectChoices () {
      // to disable field if this.field are mandatory or a binary field on Initial load
      // for other purpose use effectChoicesChange
      const setEffectToDisabled = []
      if (this.fieldIsRequired) setEffectToDisabled.push(this.$FORM('LOGIC').PROPERTIES.HIDDEN)
      if (this.fieldIsBinary) setEffectToDisabled.push(this.$FORM('LOGIC').PROPERTIES.VALUE)
      this.effectChoices.forEach(item => {
        item.disabled = setEffectToDisabled.includes(item.value)
        item.state = false
      })
    },
    setEffectCustomValue (value) {
      this.effectValueString = value
    },
    effectChoicesChange (item) {
      const otherThanHide = [this.$FORM('LOGIC').PROPERTIES.VALUE, this.$FORM('LOGIC').PROPERTIES.DISABLED]
      if (!this.fieldIsRequired && otherThanHide.includes(item.value)) {
        let ignore = false // if ignore = true, it will not change Hidden disabled value
        otherThanHide.splice(otherThanHide.indexOf(item.value), 1)
        this.effectChoices.forEach(effect => {
          if (otherThanHide.includes(effect.value) && (effect.state === true)) ignore = true
        })
        if (!ignore) find(this.effectChoices, ['value', this.$FORM('LOGIC').PROPERTIES.HIDDEN]).disabled = item.state
        if (this.fieldIsBinary) find(this.effectChoices, ['value', this.$FORM('LOGIC').PROPERTIES.VALUE]).disabled = true
      }
      if (item.value === this.$FORM('LOGIC').PROPERTIES.HIDDEN) {
        find(this.effectChoices, ['value', this.$FORM('LOGIC').PROPERTIES.DISABLED]).disabled = item.state
        find(this.effectChoices, ['value', this.$FORM('LOGIC').PROPERTIES.VALUE]).disabled = this.fieldIsBinary ? true : item.state
      }
    },
    assignOperator (val) {
      this.conditionAndOr = val
    },
    enableSetValueEffect () {
      // used just for enabling set Value if user dont set value when field are mandatory
      this.effectChoices.forEach(item => { if (item.value === this.$FORM('LOGIC').PROPERTIES.VALUE) item.state = true })
      this.errorMessage = this.$FORM('LOGIC').ERROR.MANDATORY_FIELD_SET_VALUE_ERROR
    },
    effectTooltip (ref) {
      const tooltipContent = {
        enable: false,
        text: 'test',
        target: `${ref}-span`
      }
      const isHidden = find(this.effectChoices, ['ref', 'hideButton'])
      const isDisabled = find(this.effectChoices, ['ref', 'disableButton'])
      switch (ref) {
        case 'hideButton':
          if (this.fieldIsRequired) {
            tooltipContent.enable = true
            tooltipContent.text = this.$FORM('ADVISORY_TEXT').LOGIC_HIDE_DISABLED_TOOLTIP_FOR_MANDATORY_FIELD
          } else if (isHidden.disabled) {
            tooltipContent.enable = true
            tooltipContent.text = this.$FORM('ADVISORY_TEXT').LOGIC_CANNOT_HIDE_AND_DISABLED_OR_SETVALUE_AT_SAME_TIME
          }
          return tooltipContent
        case 'disableButton':
          if (isDisabled.disabled) {
            tooltipContent.enable = true
            tooltipContent.text = this.$FORM('ADVISORY_TEXT').LOGIC_CANNOT_HIDE_AND_DISABLED_AT_SAME_TIME
          }
          return tooltipContent
        case 'setValueButton':
          if (this.fieldIsBinary) {
            tooltipContent.enable = true
            tooltipContent.text = this.$FORM('ADVISORY_TEXT').LOGIC_SET_VALUE_DISABLED_TOOLTIP_ADVISORY
          } else if (isDisabled.disabled) {
            tooltipContent.enable = true
            tooltipContent.text = this.$FORM('ADVISORY_TEXT').LOGIC_CANNOT_HIDE_AND_SETVALUE_AT_SAME_TIME
          }
          return tooltipContent
        default:
          return tooltipContent
      }
    }
  },
  watch: {
    conditions: {
      deep: true,
      handler () {
        this.clearError()
      }
    },
    effectValueString () {
      this.clearError()
    },
    effectChoices: {
      immediate: true,
      deep: true,
      handler (effects) {
        effects.forEach(effect => {
          if ((effect.value === this.$FORM('LOGIC').PROPERTIES.VALUE) && !effect.state) this.effectValueString = null
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

a.nav-link {
  background: #69696969;
  color: white;
  margin: 0px 1px 2px 1px;
}

// border for b-nav-pills
ul.nav.d-flex.justify-content-center.mb-2.logicsettingbnav.nav-pills {
    border-bottom: 1px dashed #c4c4c4;
    padding-bottom: 5px;
}

</style>
