<template>
  <div class="parent-canvas" v-if="items">
    <!-- ENTITY SELECTION -->
    <div class="entity-selection">
      <div class="scroller">
        <!-- GENERIC ENTITY FOR QUERY -->
        <div
          v-if="isForQuery"
          class="entity"
          draggable="true"
          :data-model="'_AnyEY'"
          data-type="entity"
          @dragstart="clone"
          @click="selectEntityOrLinkType"
          :title="$INSIGHT('QUERY').GENERAL.ENTITY"
          @dragend.prevent="dragEnd"
          tabindex="0"
          :aria-label="$INSIGHT('QUERY').GENERAL.ENTITY"
        >
          <entity-icon :title="$INSIGHT('QUERY').GENERAL.ENTITY" />
          <div class="name" :title="$INSIGHT('QUERY').GENERAL.ENTITY">
            {{ $INSIGHT('QUERY').GENERAL.ENTITY }}
          </div>
        </div>
        <!-- END OF GENERIC ENTITY FOR QUERY -->
        <div
          class="entity"
          v-for="(item, model) of entities"
          :key="model"
          draggable="true"
          :data-model="model"
          data-type="entity"
          @dragstart="clone"
          @click="selectEntityOrLinkType"
          :title="item.label"
          :class="{ disabled: selectedEntityModel !== false && selectedEntityModel !== model }"
          @dragend.prevent="dragEnd"
          tabindex="0"
          :aria-label="`${item.label} ${$LOCAL('ENTITY')}`"
        >
          <entity-icon :model="model" :title="item.label" />
          <div class="name" :title="item.label">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
    <!-- END OF ENTITY SELECTION -->

    <!-- CANVAS AREA -->
    <div class="center">
      <div
        class="builder"
        :class="{ adding: draggedOver }"
        @dragenter.prevent="clone"
        @dragend.prevent="draggingOver"
        @dragleave.prevent="draggedOver = false"
        @dragover.prevent="draggingOver"
        @drop.prevent="dropped"
        @click="dragEnd"
      >
        <query-canvas
          ref="QueryCanvas"
          :actionKey="actionKey"
          :usage="usage"
          :items="items"
          @moved="move"
          :linking="linking"
          :validEntities="validEntities"
          :zoom.sync="zoom"
          :offset.sync="offset"
          @linkingDone="linkingFinished"
          @showFilters="showFilters"
          @linkingCancelled="linkingCancelled"
          @setEnd1="setEnd1"
          @openFormModal="openFormModal"
          @setPrimary="setPrimary"
          @setMandatory="setMandatory"
          @checkItem="checkItem"
        />

        <div v-show="dragging !== false && dragging.type !== 'link'" class="dragging">
          <div class="message">
            {{ draggedOver === true ? $INSIGHT('QUERY').CANVAS.DROP_ENTITY : $INSIGHT('QUERY').CANVAS.DRAG_ENTITY }}
          </div>
        </div>
      </div>

      <SlidingTabArea
        ref="SlidingTabArea"
        class="sliding-tab"
        :loading="loading"
        :isForQuery="isForQuery"
        :slidingTab="slidingTab"
        :validAccessGroups="validAccessGroups"
        :disableAccessGroupOption="disableAccessGroupOption"
        :accessGroupOption="accessGroupOption"
        :accessGroupsError="accessGroupsError"
        :queryMeta="queryMeta"
        :query="query"
        :titleErrorState="titleErrorState"
        :formTitleError="formTitleError"
        :queryNameError="queryNameError"
        :compositeForm="compositeForm"
        :formMode="formMode"
        @setFormPermittedGroups="setFormPermittedGroups"
        @emitQueryAccessGroups="emitQueryAccessGroups"
      />

      <div class="actions">
        <b-button variant="danger" @click="$router.go(-1)" :disabled="loading">
          <b-spinner small v-if="loading" :label="`${$LOCAL('COMMON_WORD').CHECKING}..`"></b-spinner>
          <span v-else>{{$LOCAL('COMMON_WORD').CANCEL}}</span>
        </b-button>

        <b-button variant="success" @click="submitData" :disabled="loading">
          <b-spinner small v-if="loading" :label="`${$LOCAL('COMMON_WORD').FETCHING}..`"></b-spinner>
          <span v-else>{{$LOCAL('COMMON_WORD').SUBMIT}}</span>
        </b-button>
      </div>
    </div>
    <!-- END OF CANVAS AREA -->

    <!-- LINK SELECTION -->
    <div class="link-selection">
      <div v-if="linkEndsLoading === true" class="loading">
        <b-spinner variant="secondary" large :label="`${$LOCAL('COMMON_WORD').LOADING}..`" />
      </div>
      <div class="scroller" v-if="linkEndsLoading === false">
        <!-- GENERIC LINK FOR QUERY -->
        <div
          v-if="isForQuery"
          class="link"
          draggable="true"
          :data-model="'_AnyLK'"
          data-type="link"
          @dragstart="clone"
          @click="selectEntityOrLinkType"
          @dragend.prevent="dragEnd"
          :title="$INSIGHT('QUERY').GENERAL.LINK"
          :class="{ disabled: isAnyLinkDisabled }"
          tabindex="0"
          :aria-label="$INSIGHT('QUERY').GENERAL.LINK"
        >
          <link-icon />
          <div class="name" :title="$INSIGHT('QUERY').GENERAL.LINK">
            {{ $INSIGHT('QUERY').GENERAL.LINK }}
          </div>
        </div>
        <!-- END OF GENERIC LINK FOR QUERY -->
        <transition-group name="fade">
          <div v-for="[model, item] of validLinks" :key="model" class="fade-item">
            <div
              class="link"
              draggable="true"
              :data-model="model"
              data-type="link"
              @dragstart="clone"
              @click="selectEntityOrLinkType"
              @dragend.prevent="dragEnd"
              :title="item.label"
              :class="{ disabled: selectedLinkModel !== false && selectedLinkModel !== model}"
              tabindex="0"
              :aria-label="item.label"
            >
              <link-icon />
              <div class="name" :title="item.label">
                {{ item.label }}
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
    <!-- END OF LINK SELECTION -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EntityIcon from '../../components/EntityIcon'
import LinkIcon from '../../components/LinkIcon'
import SlidingTabArea from '@/modules/insight/components/canvas/SlidingTabArea'
import QueryCanvas from '@/modules/insight/components/canvas/QueryCanvas'

export default {
  name: 'parent-canvas-component',
  components: {
    EntityIcon,
    LinkIcon,
    QueryCanvas,
    SlidingTabArea
  },
  data: () => ({
    dragging: false,
    draggedOver: false,
    mouseDown: false,
    validEntities: [],
    linkEnds: {},
    zoom: 1,
    offset: {
      x: 0,
      y: 0
    },
    linking: false,
    linkEndsLoading: true,
    name: '',
    description: '',
    selectedLinkModel: false,
    selectedEntityModel: false,
    selectedType: false,
    errorMessage: ''
  }),
  computed: {
    ...mapState('insight', {
      entities: 'entities',
      links: 'links'
    }),
    nextId () {
      let max = 1
      for (const item of this.items) {
        if (item.id > max) {
          max = item.id
        }
      }
      return max + 1
    },
    validLinks () {
      if (this.isAnyLinkDisabled) {
        return []
      } else {
        const validLinksDetails = []
        Object.entries(this.links).forEach((item) => {
          const [linkModelName] = item
          if (this.listOfValidLinks.includes(linkModelName)) {
            validLinksDetails.push(item)
          }
        })
        return validLinksDetails
      }
    },
    isAnyLinkDisabled () {
      return this.items.length < 2
    },
    getAllChosenEntityModel () {
      return this.items.filter(item => !Object.prototype.hasOwnProperty.call(item, 'end1')).map(item => item.model)
    },
    listOfValidLinks () {
      const listOfValidLinks = []
      Object.entries(this.linkEnds).forEach(([link, meta]) => {
        this.getAllChosenEntityModel.forEach((item1, index1) => {
          this.getAllChosenEntityModel.forEach((item2, index2) => {
            if (index1 !== index2) {
              if (meta.linkends1.includes(item1) && meta.linkends2.includes(item2)) {
                listOfValidLinks.push(link)
              }
            }
          })
        })
      })
      return listOfValidLinks
    },
    isForQuery () {
      return this.usage === 'query'
    }
  },
  props: {
    usage: {
      default: 'query'
    },
    loading: {
      default: false
    },
    items: {
      default: () => []
    },
    slidingTab: {
      default: () => ({
        title: '',
        toggle: false
      })
    },
    validAccessGroups: {
      default: () => []
    },
    disableAccessGroupOption: {
      default: true
    },
    accessGroupOption: {
      default: () => ({
        data: 'O'
      })
    },
    accessGroupsError: {
      default: ''
    },
    titleErrorState: {
      default: false
    },
    // ------------------------
    // START OF PROPS FOR QUERY
    accessGroups: {
      default: () => []
    },
    queryMeta: {
      default: () => ({
        category: [],
        access_group: []
      })
    },
    query: {
      default: () => {}
    },
    queryNameError: {
      default: () => ({
        state: null,
        message: ''
      })
    },
    // END OF PROPS FOR QUERY
    // ---------------------------------
    // START OF PROPS FOR COMPOSITE FORM
    formTitleError: {
      default: () => ({
        state: null,
        message: ''
      })
    },
    compositeForm: {
      default: () => ({
        title: '',
        model: null,
        permitted_groups: [],
        permitted_groups_option: [],
        forms: []
      })
    },
    actionKey: { // TODO: Remove this IF it's not have any significant usage
      default: 0
    },
    formMode: {
      default: 'create-composite-form'
    }
    // END OF PROPS FOR COMPOSITE FORM
    // -------------------------------
  },
  async mounted () {
    this.getEntities()
    this.getLinksAndEnds()
    this.setSlidingTabTitle()
  },
  methods: {
    ...mapActions('insight', [
      'getEntities',
      'getLinks'
    ]),
    setSlidingTabTitle () {
      const routeMetaAction = this.$route.meta?.action
      const wordCreate = this.$LOCAL('COMMON_WORD').CREATE
      const wordEdit = this.$LOCAL('COMMON_WORD').EDIT

      const queryDetails = this.$INSIGHT('QUERY').ACTION
      const formDetails = this.$INSIGHT('FORM').ACTION

      if (routeMetaAction) {
        const isCreate = routeMetaAction.includes(wordCreate.toLowerCase()) // check if causing a test case error
        const actionWord = isCreate ? wordCreate : wordEdit
        const detailsWord = this.isForQuery ? queryDetails : formDetails

        this.slidingTab.title = `${actionWord} ${detailsWord}`
      }
    },
    async getLinksAndEnds () {
      await this.getLinks()

      this.linkEnds._AnyLK = {
        linkends1: [],
        linkends2: []
      }

      for (const model in this.links) {
        const { end1_types: linkends1, end2_types: linkends2 } = this.links[model]
        linkends1.push('_AnyEY')
        linkends2.push('_AnyEY')
        this.linkEnds[model] = { linkends1, linkends2 }
      }

      this.linkEndsLoading = false
    },
    submitData () {
      this.$emit('submitData')
    },
    selectEntityOrLinkType (event) {
      this.$refs.QueryCanvas.unselectItem() // to Close actions button
      // if event doesn't have a target we don't know what is selected
      if (event.target === undefined) {
        return false
      }
      // determine if this selection is a link or entity
      const isLink = event.target.closest('.link') !== null
      // if link is selected there should be at least 2 entities on the canvas
      if (isLink && this.items.length < 2) {
        return false
      }
      this.dragging = {
        model: isLink ? event.target.closest('.link').dataset.model : event.target.closest('.entity').dataset.model,
        type: isLink ? event.target.closest('.link').dataset.type : event.target.closest('.entity').dataset.type,
        record: null
      }
      if (isLink) {
        this.selectedLinkModel = this.dragging.model
      } else {
        this.selectedEntityModel = this.dragging.model
      }
      this.selectedType = this.dragging.type
      this.setValidEntities(this.dragging.model)
      this.linking = isLink
      return true
    },
    setEnd1 (entityName) {
      // should not link if end1 is not a valid end type
      const entity = this.items.find(i => i.name === entityName)
      if (this.validEntities.length > 0 && this.validEntities.includes(entity.model) === false) {
        return false
      }
      this.linking = true
      let link
      if (this.selectedLinkModel === '_AnyLK') {
        link = {
          label: this.$INSIGHT('QUERY').GENERAL.LINK
        }
      } else {
        link = this.links[this.selectedLinkModel]
      }
      link = {
        id: this.nextId,
        model: this.selectedLinkModel,
        name: this.newName(this.selectedLinkModel, link.label),
        end1: entityName,
        end2: this.$INSIGHT('CANVAS').LINK_SETTINGS.MOUSE_END,
        output: false,
        conditions: [],
        direction: 0,
        confidence: 0,
        source_type: 0,
        source_id: '',
        confirmed: false,
        primary: false
      }
      this.dragging.record = link
      this.items.push(link)
      this.validEntities = this.linkEnds[this.selectedLinkModel].linkends2
      return true
    },
    clone (event) {
      this.$refs.QueryCanvas.unselectItem() // to Close actions button
      if (event.target !== undefined && event.target.dataset !== undefined && event.target.dataset.index !== undefined) {
        return false
      }

      let model

      if (event.target !== undefined && event.target.dataset !== undefined && event.target.dataset.model !== undefined) {
        model = event.target.dataset.model
      } else {
        if (event.target.closest('.entity') === null) {
          return false
        }
        model = event.target.closest('.entity').dataset.model
      }

      let type

      if (event.target !== undefined && event.target.dataset !== undefined && event.target.dataset.type !== undefined) {
        type = event.target.dataset.type
      } else {
        if (event.target.closest('.entity') === null) {
          return false
        }
        type = event.target.closest('.entity').dataset.type
      }

      this.dragging = {
        type,
        model
      }

      event.dataTransfer.setData('model', model)
      event.dataTransfer.setData('type', type)
      return true
    },
    draggingOver (event) {
      this.draggedOver = true
      if (this.dragging !== false) {
        this.setValidEntities(this.dragging.model)
      }
    },
    setValidEntities (model) {
      const linkEnds = this.linkEnds[model]
      if (linkEnds !== undefined) {
        this.validEntities = linkEnds.linkends1
      } else {
        this.validEntities = []
      }
    },
    newName (model, label) {
      if (this.items.length < 1) {
        return `${label} 1`
      }

      let count = this.items.filter(i => i.model === model).length

      if (count === 0) {
        return `${label} 1`
      }

      count++

      while (this.items.find(i => i.name === `${label} ${count}`) !== undefined) {
        count++
      }

      return `${label} ${count}`
    },
    dropped (event) {
      const isDragAndDrop = event.dataTransfer !== undefined
      const thisModel = isDragAndDrop ? event.dataTransfer.getData('model') : this.selectedEntityModel
      const thisType = isDragAndDrop ? event.dataTransfer.getData('type') : this.selectedType
      const isLink = thisType === 'link'
      this.$emit('isLinking', isLink)

      if (!thisModel) {
        return false
      }

      if (isLink) {
        // end1 entity name is derived from the location of the event
        const end1 = this.droppedOnEntity({
          x: event.offsetX,
          y: event.offsetY
        })
        // if end1 does not exists user did not click on an entity
        if (end1 === false) {
          this.dragEnd()
          return false
        }
        this.selectedLinkModel = thisModel
        this.setEnd1(end1)
        return false
      }

      let entity = this.entities[thisModel]

      if (thisModel === '_AnyEY') {
        entity = {
          label: this.$INSIGHT('QUERY').GENERAL.ENTITY
        }
      }

      this.items.push({
        id: this.nextId,
        model: thisModel,
        name: this.newName(thisModel, entity.label),
        top: event.offsetY,
        left: event.offsetX,
        output: false,
        conditions: [],
        source_type: 0,
        source_id: '',
        confirmed: false,
        // for primary form setting
        primary: false,
        // for mandatory form setting
        canSetAsMandatory: true,
        isMandatory: true
      })
      if (isDragAndDrop) {
        this.dragEnd()
      }
      return true
    },
    dragEnd (event = false) {
      if (this.linking === true) {
        return false
      }
      if (this.selectedType === 'entity') {
        this.dropped(event)
      }
      this.dragging = false
      this.draggedOver = false
      this.validEntities = []
      this.selectedType = false
      this.selectedEntityModel = false
      this.selectedLinkModel = false
      return true
    },
    move (event) {
      const item = this.items.find(i => i.id === event.id)

      if (item === undefined) {
        return false
      }

      item.top = event.top
      item.left = event.left

      this.$set(this.items, this.items.findIndex(i => i.id === event.id), item)
      return true
    },
    droppedOnEntity (position) {
      const width = 50
      const height = 50

      const x = position.x
      const y = position.y

      for (const item of this.items.filter(i => i.end1 === undefined)) {
        if (this.validEntities.length > 0 && this.validEntities.includes(item.model) === false) {
          continue
        }
        const itemTop = (item.top * this.zoom) + this.offset.y
        const top = itemTop - (height / 2)
        const bottom = itemTop + (height / 2)
        const itemLeft = (item.left * this.zoom) + this.offset.x
        const left = itemLeft - (width / 2)
        const right = itemLeft + (width / 2)

        if (x < left || x > right) {
          continue
        }
        if (y < top || y > bottom) {
          continue
        }
        return item.name
      }

      return false
    },
    async linkingCancelled () {
      this.linking = false
      if (this.dragging !== false && this.dragging.record !== null) {
        const items = this.items.filter(i => i.name !== this.dragging.record.name)
        this.$emit('changeItemsDirectly', items)
      }
      this.selectedLinkModel = false
      this.dragging = false
    },
    linkingFinished () {
      this.linking = false
      this.selectedLinkModel = false
      this.dragEnd()
    },
    showFilters (item) {
      this.$emit('showFilters', item)
    },
    // ==================================
    // METHODS USED FOR COMPOSITE FORM
    openFormModal (item) {
      this.$emit('openFormModal', item)
    },
    setPrimary (item) {
      this.$emit('setPrimary', item)
    },
    setMandatory (item) {
      this.$emit('setMandatory', item)
    },
    checkItem (item) {
      this.$emit('checkItem', item)
    },
    setFormPermittedGroups (value) {
      this.$emit('setFormPermittedGroups', value)
    },
    setFormPermittedGroupsOption (permittedGroupsOption) {
      this.$refs.SlidingTabArea.setFormPermittedGroupsOption(permittedGroupsOption)
    },
    // END OF METHODS USED FOR COMPOSITE FORM
    // ==================================
    // METHODS USED FOR QUERY
    setQueryAccessGroups (value) {
      this.$refs.SlidingTabArea.accessGroups = value
    },
    emitQueryAccessGroups (value) {
      this.$emit('emitQueryAccessGroups', value)
    }
    // ==================================
    // END OF METHODS USED FOR QUERY
  }
}
</script>

<style lang="scss" scoped>
.parent-canvas {
  margin: 0px;
  display: flex;
  height: calc(100vh - 56px);
  position: relative;
}

.entity-selection {
  position: relative;
  background: #FFF;
  border-right: 1px solid #e0e0e0;
  height: 100%;
  width: 127px;
}

.link-selection {
  position: relative;
  background: #FFF;
  border-left: 1px solid #e0e0e0;
  height: 100%;
  width: 127px;
}

.sliding-tab {
  border-top: 1px solid #e0e0e0;
}

.actions {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 15px 15px;
  position: sticky;
  background: #FFFFFF;
  box-shadow: 3px -8px 19px 2px rgba(0,0,0,0.04);
}

.center {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.builder {
  position: relative;
  flex-grow: 1;
  overflow: hidden;

  .item {
    position: absolute;
    user-select: none;
    padding: 10px;
    margin: 0 5px;
    width: 100px;
  }

  .dragging {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background: rgba(#000, 0.1);
  }
}

.scroller {
  padding: 15px 0;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .entity,
  .link {
    user-select: none;
    padding: 10px;
    margin: 0 5px;
    width: 100px;
    border: 1px solid transparent;
    border-radius: 5px;
    cursor: copy;;

    &:hover {
      box-shadow: 1px 2px 10px rgba(0, 0, 0, .15);
      -webkit-transition: box-shadow 0.5s;
      transition: box-shadow 0.5s;

      /deep/ .entity-icon {
        svg {
          path {
            fill: #F08521;
            -webkit-transition: fill 0.2s;
            transition: fill 0.2s;
          }
        }
      }
    }

    &.dragging {
      cursor: move;

      /deep/ .entity-icon {
        cursor: move;

        svg {
          path {
            fill: #F08521;
          }
        }
      }
    }

    /deep/ .entity-icon,
    /deep/ .link-icon {
      cursor: copy;
    }

    .name {
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 7px;
    }
  }
}

.entity.disabled,
.link.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  /deep/ .link-icon {
    cursor: not-allowed;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(201, 201, 201);
}

.fade-item {
  transition: all 1s;
}
</style>
