<template>
  <div class="query-canvas" id="query-canvas">
    <v-stage
      ref="canvas"
      :config="configKonva"
      @dragstart="handleDrag"
      @dragend="handleDrag"
      @dragmove="handleDrag"
      @click.self="closeAction"
      @wheel="panZoom"
      @mousemove="mouseend"
    >
      <v-layer>
        <query-link
          v-for="item of links"
          :usage="usage"
          :key="item.id"
          :id="item.id"
          :actionKey="actionKey"
          :direction="item.direction"
          :confidence="item.confidence"
          :name="item.name"
          :end1="entities.find(e => e.name === item.end1)"
          :end2="item.end2 !== 'MouseEnd' ? entities.find(e => e.name === item.end2) : mouseEnd"
          :radius="item.end2 !== 'MouseEnd' ? 25 : 0"
          :output="item.output"
          :linkConfirmed="item.confirmed"
          :selected="isSelected(item.id)"
          @selected="selectItem(item)"
          @action="openLinkAction"
        />
      </v-layer>
      <v-layer>
        <query-entity
          type="entity"
          @selected="selectItem(item)"
          @click.native.stop
          @action="openAction"
          :usage="usage"
          :stage="stage"
          v-for="item of entities"
          :key="item.id"
          :actionKey="actionKey"
          :item="item"
          :selected="isSelected(item.id)"
          :disabled="validEntities.length > 0 && validEntities.includes(item.model) === false"
          :linking="linking"
        />
      </v-layer>
    </v-stage>

    <entity-actions
      v-for="item of entities"
      :key="'entity-actions' + item.id"
      :item="item"
      :selected="isSelected(item.id)"
      :position="actionPositionAdjusted"
      @close="selected = false"
      @setOutput="setOutput(item)"
      @removeOutput="removeOutput(item)"
      @setConditions="setConditions(item)"
      @remove="removeEntity(item)"
      @updateName="updateName"
      @openFormModal="openFormModal(item)"
      @setPrimary="$emit('setPrimary', item)"
      @setMandatory="$emit('setMandatory', item)"
      :style="actionStyle"
      :usage="usage"
    />

    <link-actions
      v-for="item of links"
      :usage="usage"
      :key="'actions' + item.id"
      :item="item"
      :selected="isSelected(item.id)"
      :position="actionPositionAdjusted"
      @openFormModal="openFormModal(item)"
      @close="selected = false"
      @setOutput="setOutput(item)"
      @removeOutput="removeOutput(item)"
      @setConditions="setConditions(item)"
      @remove="removeLink(item.id)"
      @update="update"
      :style="linkActionStyle"
    />
  </div>
</template>

<script>
import QueryEntity from '../../components/canvas/QueryEntity'
import QueryLink from '../../components/canvas/QueryLink'
import EntityActions from '../../components/canvas/EntityActions'
import LinkActions from '../../components/canvas/LinkActions'
import { throttle } from 'lodash'
import '@/dependencies/konva_dependencies'

export default {
  name: 'query-canvas',
  components: {
    QueryEntity,
    QueryLink,
    EntityActions,
    LinkActions
  },
  props: {
    usage: {
      default: 'query'
    },
    items: {
      default: () => ([])
    },
    validEntities: {
      default: () => ([])
    },
    linking: {
      default: false
    },
    disabled: {
      default: false
    },
    zoom: {
      default: 1
    },
    offset: {
      default: () => ({
        x: 0,
        y: 0
      })
    },
    actionKey: {
      default: 0
    }
  },
  data: () => ({
    configKonva: {
      width: 200,
      height: 200,
      draggable: true,
      dragDistance: 10,
      name: 'Stage',
      scale: {
        x: 1,
        y: 1
      }
    },
    selected: false,
    actionPosition: {
      top: 0,
      left: 0
    },
    stage: false,
    actionRotate: 0,
    mouseEnd: {
      top: 0,
      left: 0
    }
  }),
  computed: {
    actionPositionAdjusted () {
      const position = {
        y: (this.actionPosition.top * this.zoomProxy) + this.position.y,
        x: (this.actionPosition.left * this.zoomProxy) + this.position.x
      }
      return position
    },
    actionStyle () {
      const style = {
        top: (this.actionPositionAdjusted.y) + 'px',
        left: (this.actionPositionAdjusted.x) + 'px',
        transform: 'scale(' + this.zoomProxy + ') '
      }
      return style
    },
    linkActionStyle () {
      const style = {
        top: (this.actionPositionAdjusted.y) + 'px',
        left: (this.actionPositionAdjusted.x) + 'px',
        transform: 'scale(' + this.zoomProxy + ') translate(-50%, -50%)'
      }
      return style
    },
    entities () {
      return this.items.filter((item) => {
        return item.end1 === undefined
      })
    },
    links () {
      return this.items.filter((item) => {
        return item.end1 !== undefined
      })
    },
    zoomProxy: {
      get () {
        return this.zoom
      },
      set (value) {
        this.$emit('update:zoom', value)
      }
    },
    position: {
      get () {
        return this.offset
      },
      set (value) {
        this.$emit('update:offset', value)
      }
    }
  },
  watch: {
    zoomProxy () {
      this.$refs.canvas.getStage().scale({ x: this.zoomProxy, y: this.zoomProxy }).batchDraw()
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.setCanvasSize)
      this.setCanvasSize()
    })
    this.stage = this.$refs.canvas
  },
  methods: {
    setCanvasSize () {
      this.configKonva.width = document.documentElement.clientWidth - 440
      this.configKonva.height = document.documentElement.clientHeight - 170
    },
    closeAction (event) {
      if (event.target.id() === this.selected) return
      if (this.linking === true) this.$emit('linkingCancelled')

      this.unselectItem()
    },
    /**
     * Eventlistener that handles the dragstart, dragmove and dragend event that is happening in the canvas.
     * There are 2 distinct element, "Stage" and "Entity" that will be rendered in the canvas.
     *
     * Dragging "Link" type element will be treated as a "Stage" type element as the actual implementation is moving
     * the stage and all elements position in the chart will be offset by the "Stage" position
     *
     * Dragging "Entity" type element will emit a "moved" event to update the dragged "Entity" position
     * @param e The event object that was triggered by either dragstart/dragmove/dragend
     */
    handleDrag (e) {
      if (e.target.attrs.name === 'Stage' && e.target._lastPos !== null) {
        this.position = e.target._lastPos
      } else {
        this.$emit('moved', {
          id: e.target.id(),
          top: e.target.attrs.y,
          left: e.target.attrs.x
        })
      }
    },
    isSelected (id) {
      return this.selected === id
    },
    unselectItem () {
      this.selected = false
      this.mouseEnd = {
        top: 0,
        left: 0
      }
    },
    selectItem (item) {
      this.$emit('checkItem', item)

      if (this.linking === true) {
        const link = this.items.find(i => i.end2 === 'MouseEnd')

        if (link === undefined) {
          this.$emit('setEnd1', item.name)
          return
        }
        if (this.validEntities.length > 0 && this.validEntities.includes(item.model) === false) return

        if (item.name === link.end1) {
          this.removeLink(link.id)
          this.$emit('linkingCancelled')
          this.mouseEnd = {
            top: 0,
            left: 0
          }
          return
        }

        this.update({
          item: link,
          value: {
            end2: item.name
          }
        })

        this.$emit('linkingDone')

        return
      }

      if (this.isSelected(item.id) === true) {
        this.unselectItem()
      } else {
        this.selected = item.id
      }
    },
    openAction ({ id, position }) {
      this.actionPosition = position
      this.actionRotate = 0
    },
    openLinkAction ({ rotate, position }) {
      this.actionPosition = position
      this.actionRotate = rotate
    },
    getEntityLinks (item) {
      return this.links.filter(l => l.end1 === item.name || l.end2 === item.name)
    },
    removeEntity (item) {
      // Remove any links that entity is referenced in
      const links = this.getEntityLinks(item)
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const link of links) {
        this.removeLink(link.id)
      }

      const index = this.items.findIndex(v => v.id === item.id)

      if (index >= 0) {
        this.items.splice(index, 1)
      }
    },
    removeLink (id) {
      const index = this.items.findIndex(v => v.id === id)

      if (index >= 0) {
        this.items.splice(index, 1)
      }
    },
    setOutput (item) {
      item.output = true
    },
    setConditions (item) {
      this.$emit('showFilters', item)
    },
    removeOutput (item) {
      item.output = false
    },
    updateName ({ item, value }) {
      const links = this.getEntityLinks(item)
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const link of links) {
        if (link.end1 === item.name) {
          link.end1 = value
        }

        if (link.end2 === item.name) {
          link.end2 = value
        }
      }
      item.name = value
    },
    update ({ item, value }) {
      const index = this.items.findIndex(i => i.id === item.id)

      item = {
        ...item,
        ...value
      }

      this.$set(this.items, index, item)
    },
    panZoom (event) {
      const zoomStep = 1.05

      let zoom

      if (event.evt.deltaY > 0) {
        zoom = this.zoomProxy / zoomStep
      } else if (event.evt.deltaY < 0) {
        zoom = this.zoomProxy * zoomStep
      }

      if (zoom < 0.5) {
        zoom = 0.5
      }

      this.zoomProxy = zoom
    },
    mouseend: throttle(function (e) {
      if (this.linking === true) {
        this.mouseEnd = {
          top: (e.evt.offsetY / this.zoom) - (this.position.y / this.zoom),
          left: (e.evt.offsetX / this.zoom) - (this.position.x / this.zoom)
        }
      }
    }, 30),
    openFormModal (item) {
      this.$emit('openFormModal', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.query-canvas {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  .actions {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
