<template>
    <div class="inner-query" @click.stop="$emit('close')">

        <div class="circle edit" :class="{ open: editName === true }" @click.prevent.stop="$emit('toggleEditName')" v-b-tooltip:query-canvas.hover :title="'Edit Name'">
          <div class="inside">
            <la-edit />
            <div class="popout" @click.prevent.stop v-if="editName === true && selected === true">
              <b-input :value="item.name" @input="emitName" />
            </div>
          </div>
        </div>

        <div class="circle edit" @click.prevent.stop="$emit('setConditions')" v-b-tooltip:query-canvas.hover :title="'Filter'">
          <div class="inside">
            <la-filter />
          </div>
        </div>

        <div class="circle set-output" @click.prevent.stop="$emit(item.output === true ? 'removeOutput' : 'setOutput')" v-b-tooltip:query-canvas.hover :title="item.output === false ? 'Set as output' : 'Remove as output'" >
          <div class="inside">
            <la-file-export />
          </div>
        </div>

        <div class="circle delete" @click.prevent.stop="$emit('remove')" v-b-tooltip:query-canvas.hover :title="'Remove Entity'">
          <div class="inside">
            <la-trash />
          </div>
        </div>

      </div>
</template>

<script>
import LaTrash from '@/assets/la-trash.svg'
import LaEdit from '@/assets/la-edit.svg'
import LaFileExport from '@/assets/la-file-export.svg'
import LaFilter from '@/assets/la-filter.svg'

export default {
  name: 'entity-actions-for-query',
  components: {
    LaTrash,
    LaEdit,
    LaFileExport,
    LaFilter
  },
  props: {
    editName: {
      default: false
    },
    item: {
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitName (value) {
      this.$emit('emitName', value)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/modules/insight/components/canvas/EntityActionsButton/entityactions.scss';
</style>
