<template>
  <transition
      name="spin"
      tag="div"
  >
    <div id="entity-actions"
      class="actions"
      v-if="selected === true"
    >
      <EntityActionsForQuery
        v-if="usage === `query`"
        :editName="editName"
        :item="item"
        :selected="selected"
        @emitName="emitName"
        @toggleEditName="toggleEditName"
        @close="$emit('close')"
        @setConditions="$emit('setConditions')"
        @removeOutput="$emit('removeOutput')"
        @setOutput="$emit('setOutput')"
        @remove="$emit('remove')"
      />

      <EntityActionsForForm
        v-if="usage === `form`"
        :item="item"
        isFor="entityActions"
        @openFormModal="$emit('openFormModal')"
        @setPrimary="$emit('setPrimary')"
        @setMandatory="$emit('setMandatory')"
        @close="$emit('close')"
        @remove="$emit('remove')"
      />

    </div>
  </transition>
</template>

<script>
import EntityActionsForQuery from '@/modules/insight/components/canvas/EntityActionsButton/EntityActionsForQuery'
import EntityActionsForForm from '@/modules/insight/components/canvas/EntityActionsButton/EntityActionsForForm'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'entity-actions',
  components: {
    EntityActionsForQuery,
    EntityActionsForForm
  },
  props: {
    usage: {
      default: 'query'
    },
    item: {
      required: true
    },
    position: {
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    links: {
      type: [Object, Array],
      default: () => ([])
    },
    newLink: {
      model: '',
      name: '',
      direction: null
    }
  },
  data: () => ({
    manageLinks: false,
    editName: false,
    directions: [
      {
        value: 0,
        text: 'None'
      },
      {
        value: 1,
        text: 'Right'
      },
      {
        value: 2,
        text: 'Left'
      },
      {
        value: 3,
        text: 'Both'
      }
    ]
  }),
  computed: {
    ...mapState('insight', {
      linkTypes: 'links'
    })
  },
  watch: {
    selected () {
      this.manageLinks = false
      this.editName = false
    }
  },
  mounted () {
    this.getLinks()
  },
  methods: {
    ...mapActions('insight', ['getLinks']),
    toggleEditName () {
      this.manageLinks = false
      this.editName = !this.editName
    },
    toggleManageLinks () {
      this.manageLinks = !this.manageLinks
      this.editName = false
    },
    emitName (value) {
      this.$emit('updateName', {
        item: this.item,
        value
      })
    },
    emitLink (link, value) {
      this.$emit('updateLink', {
        link,
        value
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }

  30% {
    opacity: 0;
  }
}

.spin-enter-active {
  animation:0.2s spin ease-out;
}

.spin-leave-active {
  animation:0.2s spin ease-out reverse;
}

.actions {
  transform-origin: top left;
}
</style>
