import { UndirectedGraph, BreadthFirstPaths } from '@ganorberg/data-structures-javascript'
import { isEmpty, pickBy, find } from 'lodash'

export default {
  data: () => ({
    graphItems: [],
    usedForVisualisation: false // insight/views/show's cytoscape visualisation
  }),
  methods: {
    createStructuredGraph (itemsParameter, forVisualisation = false) {
      const graphItems = isEmpty(this.graphItems) ? itemsParameter : this.graphItems
      const usedForVisualisation = this.usedForVisualisation || forVisualisation // visualisation: tactical visualisation in insight's show.vue

      // This method is use to create graph that is undirected and with BFS paths.
      // REMEMBER: Anything inside items.paths: for distanceFromSource, visited, etc, the ID (node name) will turn into String
      const items = {
        links: [],
        entities: [],
        primary: false,
        graph: false,
        paths: false
      }

      // exception, if graphItems given is empty, just return an empty items data
      if (isEmpty(graphItems)) {
        return items
      }

      // Separate Between links and entites, also define the primary model
      graphItems.forEach(item => {
        const isLink = item.end1
        const isPrimary = item.primary
        if (isLink) {
          const end1 = find(graphItems, ['name', item.end1])
          const end2 = find(graphItems, ['name', item.end2])
          const end1StringId = end1?.id || end1?.Id
          const end2StringId = end2?.id || end2?.Id

          // for visualisation id is string | for composite form id need to be integer
          item.end1Id = usedForVisualisation ? end1?.id : parseInt(end1StringId)
          item.end2Id = usedForVisualisation ? end2?.id : parseInt(end2StringId)
          items.links.push(item)
        } else {
          items.entities.push(item)
        }
        if (isPrimary) {
          items.primary = item
        }
      })

      // build a graph based on links
      // class UndirectedGraph() => see this for docs: https://github.com/ganorberg/data-structures-javascript/blob/master/structures/graph.unweighted.undirected.js
      items.graph = new UndirectedGraph()
      items.links.forEach(link => {
        const onMouseEnd = link.end2 === this.$INSIGHT('CANVAS').LINK_SETTINGS.MOUSE_END
        if (!onMouseEnd) {
          items.graph.addEdge([link.end1Id, link.end2Id])
        }
      })

      const noBrokenLinks = items.graph.totalVertices === items.entities.length
      const primaryExists = !isEmpty(items.primary)

      // In order to calculate BFS, there must be no broken links, thus the need to evaluate
      const haveConditionToCreateBFS = usedForVisualisation ? noBrokenLinks : (noBrokenLinks && primaryExists)

      if (haveConditionToCreateBFS) {
        const primaryId = items.primary?.id || items.primary?.Id
        const paths = new BreadthFirstPaths(items.graph, primaryId)
        items.paths = paths
      }
      return items
    },
    getParentItem (item) {
      const graph = this.createStructuredGraph()
      const itemId = item?.id || item?.Id

      if (graph.paths) {
        const listOfParent = graph.paths.parent
        const parentId = listOfParent[itemId]
        const sameAsParentId = (item) => {
          const itemId = item?.id || item?.Id
          const itemIdStringified = itemId?.toString()
          const parentIdStringified = parentId?.toString()
          return itemIdStringified === parentIdStringified
        }
        const parentItem = graph.entities.find(sameAsParentId)
        return parentItem
      } else {
        return false
      }
    },
    getChildItems (item) {
      // (s) Plural : child can be more than one
      const graph = this.createStructuredGraph()
      const listOfParentId = graph.paths.parent
      const itemId = item?.id || item?.Id
      const sameAsItemId = (id) => {
        const idStringified = id?.toString()
        const itemIdStringified = itemId?.toString()
        return idStringified === itemIdStringified
      }
      const childIdList = Object.keys(pickBy(listOfParentId, sameAsItemId))
      const childItemList = []

      childIdList.forEach(childId => {
        const condition = (entity) => {
          const entityId = entity?.id || entity?.Id
          const childIdStringified = childId?.toString()
          const entityIdStringified = entityId?.toString()
          return childIdStringified === entityIdStringified
        }
        const childItem = graph.entities.find(condition)
        childItemList.push(childItem)
      })
      return childItemList
    },
    findShortestPathFrom (id) {
      const structuredGraph = this.createStructuredGraph()

      const paths = structuredGraph.paths
      const shortestPaths = paths.shortestPathTo(id)
      return shortestPaths
    }
  }
}
