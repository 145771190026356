<template>
    <v-group>
      <v-circle
        :config="{
          radius: 50,
          fill: 'black',
          opacity: 1,
          width: 18,
          height: 18,
          x: 22,
          y: -22
        }"
      />
      <v-text :config="{
        text: '*',
        fontFamily: 'Arial',
        fill: 'white',
        fontSize: 29,
        x: 16.5,
        y: -28
       }"
      />
    </v-group>
</template>

<script>

export default {
  name: 'mandatory-icon'
}
</script>
