<template>
  <div class="entity-icon" @click.prevent="$emit('click')" :title="title">
    <b-img v-if="customIcon" class="icon custom fade-in" :src="customIcon" />
    <div v-else class="icon">
      <icon-entity />
    </div>
  </div>
</template>

<script>
import ModelService from '@/modules/insight/services/model.service'
import IconEntity from '@/assets/entity.svg'

export default {
  name: 'entity-icon',
  components: {
    IconEntity
  },
  props: {
    model: {
      type: [Boolean, String],
      default: false
    },
    title: {
      default: null
    },
    usage: {
      default: null
    }
  },
  data: () => ({
    customIcon: false
  }),
  mounted () {
    if (this.model !== false && this.model !== 'any') {
      this.getIconImage()
    }
  },
  methods: {
    async getIconImage () {
      const response = await ModelService.getIconImage(this.model)

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.customIcon = url
      } else {
        this.customIcon = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/bootstrap/scss/bootstrap';
.entity-icon {
  flex-grow: 0;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  a {
    display: block;
    width: 100%;
  }

  /deep/ svg {
    transition: all 150ms ease-in-out;
    width: 65%;
    height: 65%;
  }

  .icon {
    border: 1px solid #e0e0e0;
    border-radius: 100%;
    overflow: hidden;
    width: 50px;
    height: 50px;
    fill: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    /deep/ svg {
      fill: #F08521;
    }
  }
}
</style>
